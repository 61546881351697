import { useEffect, useState } from "react";
import Multiselect from 'multiselect-react-dropdown';
import globalVals from "../../../globalVals";

export default function ParentsForm(props) {
  const formNum = parseInt(props.formNumber);
  const formIden = "parent_" + formNum;

  const FavSportOptions = globalVals.FavSportOptions;
  const InterestHobbiesOptions = globalVals.InterestHobbiesOptions;

  const [parentFinal, setParentFinal] = useState(props.parentsAllData[formIden]);

  const [showIdInout, setshowIdInout] = useState(false);
  const [specialAssComm, setSpecialAssComm] = useState(false);
  const [years, setYears] = useState([]);
  const [sassspecifyOptions, setSassspecifyOptions] = useState([]);
  const [allNationalities, setallNationalities] = useState([]);

  useEffect(() => props.parentsSetData({ ...props.parentsAllData, [formIden]: parentFinal }), [parentFinal]);

  useEffect(() => {
    if (parentFinal.csassintance_ === "Y") {
      setSpecialAssComm(true);
    } else {
      setSpecialAssComm(false);
    }
    if (parentFinal.cidType_ != "") {
      setshowIdInout(true);
    } else {
      setshowIdInout(false);
    }

    let tempYears = [];
    let cYear = new Date().getFullYear();
    for (let y = 1900; y <= cYear; y++) {
      tempYears.push(<option key={y} value={y}>{y}</option>);
    }

    let tempNati = [];
    globalVals.countries.map((i, k) => {
      tempNati.push(<option key={k} value={i.isocode}>{i.nationality}</option>);
    });
    setallNationalities(tempNati);
    setYears(tempYears);

    let tempsassspecifyOptions = [];
    globalVals.disabilitiesOptions.map((i, k) => {
      tempsassspecifyOptions.push(<option key={k} value={i.disability}>{i.disability}</option>);
    });
    setSassspecifyOptions(tempsassspecifyOptions);
  }, []);


  return (
    <>
      <div className="container mb-5">
        <div className="row">
          <a href="javascript:;" className="mainColor text-decoration-none"> Family member #{formNum} </a>
          <h5 className="mt-2"><b>Personal Data</b></h5>
        </div>
        <div className="row g-3 g-md-5 mb-3 mb-md-5">
          <div className="col-md-6">
            <label htmlFor={"fullNameID_" + formNum} className="form-label"> Full Name * </label>
            <input type="text" className="form-control" id={"fullNameID_" + formNum} name={"fullNameID_" + formNum} required value={parentFinal.cname_} onChange={(e) => setParentFinal({ ...parentFinal, cname_: e.target.value })} />
          </div>
          <div className="col-md-6">
            <label htmlFor={"relationship_" + formNum} className="form-label"> Relationship * </label>
            <div className="d-flex">
              <select className="w-100 px-2" id={"relationship_" + formNum} name='relationship' required value={parentFinal.cRelation_} onChange={(e) => setParentFinal({ ...parentFinal, cRelation_: e.target.value })} >
                <option>Select</option>
                <option value={"FATHER"}>Father</option>
                <option value={"MOTHER"}>Mother</option>
                <option value={"Father-in-law"}>Father in Law</option>
                <option value={"Mother-in-law"}>Mother in Law</option>
                <option value={"Sister Of"}>Sister</option>
                <option value={"Brother"}>Brother</option>
                <option value={"Cousin"}>Cousin</option>
                <option value={"Grandfather"}>Grandfather</option>
                <option value={"Grandmother"}>Grandmother</option>
                <option value={"Other"}>Other</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <p><label htmlFor={"cidType_" + formNum} className="form-label"> ID Type * </label></p>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" required={true} value={"ID_NUMBER"} id={"cidType_" + formNum} name={"cidType_" + formNum} onChange={(e) => { setshowIdInout(true); setParentFinal({ ...parentFinal, cidType_: e.target.value }) }} checked={((parentFinal.cidType_ === "ID_NUMBER") ? true : false)} />
              <label className="form-check-label" htmlFor={"cidType_" + formNum}> ID </label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" required={true} value={"PASSPORT"} id={"cidTypeP_" + formNum} name={"cidType_" + formNum} onChange={(e) => { setshowIdInout(true); setParentFinal({ ...parentFinal, cidType_: e.target.value }) }} checked={((parentFinal.cidType_ === "PASSPORT") ? true : false)} />
              <label className="form-check-label" htmlFor={"cidTypeP_" + formNum}> Passport </label>
            </div>
          </div>
          <div className="col-md-6">
            {showIdInout &&
              <>
                <label htmlFor={"idNumber_" + formNum} className="form-label"> ID / Passport * </label>
                <input type="text" className="form-control" id={"idNumber_" + formNum} name={"idNumber_" + formNum} required={true} value={parentFinal.cid_} onChange={(e) => setParentFinal({ ...parentFinal, cid_: e.target.value })} />
              </>
            }
          </div>
          <div className="col-md-6">
            <label className="mb-2" id={"dob_day_" + formNum}>Date of birth*</label>
            <div className="d-flex gap-2">
              <select className="w-auto px-2" id={"dob_day_" + formNum} name='dob_day' required value={parentFinal.cdobDay_} onChange={(e) => setParentFinal({ ...parentFinal, cdobDay_: e.target.value })} >
                <option key={1} value>Day</option>
                <option key={2} value="01">1</option>
                <option key={3} value="02">2</option>
                <option key={4} value="03">3</option>
                <option key={5} value="04">4</option>
                <option key={6} value="05">5</option>
                <option key={7} value="06">6</option>
                <option key={8} value="07">7</option>
                <option key={9} value="08">8</option>
                <option key={10} value="09">9</option>
                <option key={11} value="10">10</option>
                <option key={12} value="11">11</option>
                <option key={13} value="12">12</option>
                <option key={14} value="13">13</option>
                <option key={15} value="14">14</option>
                <option key={16} value="15">15</option>
                <option key={17} value="16">16</option>
                <option key={18} value="17">17</option>
                <option key={19} value="18">18</option>
                <option key={20} value="19">19</option>
                <option key={21} value="20">20</option>
                <option key={22} value="21">21</option>
                <option key={23} value="22">22</option>
                <option key={24} value="23">23</option>
                <option key={25} value="24">24</option>
                <option key={26} value="25">25</option>
                <option key={27} value="26">26</option>
                <option key={28} value="27">27</option>
                <option key={29} value="28">28</option>
                <option key={30} value="29">29</option>
                <option key={31} value="30">30</option>
                <option key={32} value="31">31</option>
              </select>
              <select className="w-auto px-2" id={"dob_month_" + formNum} name='dob_montg' required value={parentFinal.cdobMonth_} onChange={(e) => setParentFinal({ ...parentFinal, cdobMonth_: e.target.value })} >
                <option key={1} value>Month</option>
                <option key={2} value="01">Jan.</option>
                <option key={3} value="02">Feb.</option>
                <option key={4} value="03">Mar.</option>
                <option key={5} value="04">Apr.</option>
                <option key={6} value="05">May.</option>
                <option key={7} value="06">Jun.</option>
                <option key={8} value="07">Jul.</option>
                <option key={9} value="08">Aug.</option>
                <option key={10} value="09">Sept.</option>
                <option key={11} value="10">Oct.</option>
                <option key={12} value="11">Nov.</option>
                <option key={13} value="12">Dec.</option>
              </select>
              <select className="w-auto px-2" id={"dob_year_" + formNum} name='dob_year' required value={parentFinal.cdobYear_} onChange={(e) => setParentFinal({ ...parentFinal, cdobYear_: e.target.value })} >
                <option key={1} value>Year</option>
                {years}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="nationality" className="form-label"> Nationality * </label>
            <select className="w-100 px-2" required id={"nationality_" + formNum} value={parentFinal.cnationality_} onChange={(e) => setParentFinal({ ...parentFinal, cnationality_: e.target.value })}>
              <option>Choose</option>
              {allNationalities}
            </select>
          </div>
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <h5 className=""><b>Community</b></h5>
        </div>
        <div className="row g-3 g-md-5 mb-3 mb-md-5">
          <div className="col-md-6">
            <label htmlFor={"childSports_" + formNum} className="mb-2"> Favorite Sports (Select one or more) *</label>
            <div className="dropdown w-100" id={"childSports_" + formNum}>

              <Multiselect
                options={FavSportOptions}
                selectedValues={parentFinal.csports_}
                onSelect={(values) => setParentFinal({ ...parentFinal, csports_: values })}
                onRemove={(values) => setParentFinal({ ...parentFinal, csports_: values })}
                displayValue="sport"
                required={true}
              />

            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor={"childHobbies_" + formNum} className="mb-2"> Interests and Hobbies * </label>
            <div className="dropdown w-100" id={"childHobbies_" + formNum}>

              <Multiselect
                options={InterestHobbiesOptions}
                selectedValues={parentFinal.chobbies_}
                onSelect={(values) => setParentFinal({ ...parentFinal, chobbies_: values })}
                onRemove={(values) => setParentFinal({ ...parentFinal, chobbies_: values })}
                displayValue="intHob"
                required={true}
              />

            </div>
          </div>
          <div className="col-md-6">
            <p>Any special impairment that needs special assistance *</p>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name={"syes_" + formNum} id={"syes_" + formNum} value="Y" onChange={(e) => { setSpecialAssComm(true); setParentFinal({ ...parentFinal, csassintance_: e.target.value }) }} checked={((parentFinal.csassintance_ === "Y") ? true : false)} />
              <label className="form-check-label" htmlFor={"syes_" + formNum}> Yes </label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name={"sno_" + formNum} id={"sno_" + formNum} value="N" onChange={(e) => { setParentFinal({ ...parentFinal, csassintanceComm_: "" }); setSpecialAssComm(false); setParentFinal({ ...parentFinal, csassintance_: e.target.value }) }} checked={((parentFinal.csassintance_ === "N") ? true : false)} />
              <label className="form-check-label" htmlFor={"sno_" + formNum}> No </label>
            </div>
          </div>
          {specialAssComm &&
            <div className="col-md-6">
              <label htmlFor={"sspecify_" + formNum} className="form-label"> If yes please specify * </label>
              <select className="w-100 px-2" id={"sspecify_" + formNum} name={"sspecify_" + formNum} value={parentFinal.csassintanceComm_} onChange={(e) => setParentFinal({ ...parentFinal, csassintanceComm_: e.target.value })} required={true}>
                <option key={1} value>Select Disability</option>
                {sassspecifyOptions}
              </select>
            </div>
          }
        </div>
      </div>
    </>
  );
}
