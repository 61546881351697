import { Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Header from "./components/Header";
import Home from "./components/Home/Home";
import Footer from "./components/Footer";
import PersonalAddress from "./components/CollectInfo/PleasureToMeetYou";
import EagerToKnowBetter from "./components/CollectInfo/EagerToKnowBetter";
import FamilyInfo from "./components/CollectInfo/FamilyInfo";
import WelcomeHome from "./components/WelcomeHome";

// import welComeMedla from "./components/Home/LoggedInText";
// import SessionExpired from "./components/Home/LoggedInText";
// import WelcomeMedal from "./components/Home/WelcomeMedal";
// import AccountProblem from "./components/Home/AccountProblem";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/pleasure-to-meet-you" element={<PersonalAddress />} />
        <Route exact path="/eager-to-know-better" element={<EagerToKnowBetter />} />
        <Route exact path="/family-info" element={<FamilyInfo />} />
        <Route exact path="/welcome-back" element={<WelcomeHome />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
