import { useLocation } from 'react-router-dom';
import logo from "../assets/mv-logo.jpg";
import globalVals from '../globalVals';

const Header = () => {

  const locationInfo = useLocation().pathname;

  return (
    <div className="container d-flex justify-content-between align-items-center my-5">
      <a href="/"><img src={logo} width="150px" alt="Mountain View Logo" /></a>
      {
        ((locationInfo !== "/login") ? ((locationInfo === "/" && !(globalVals.loggedIn && globalVals.userName)) ? <a href="/login" className="btn btn-primary bg-white rounded-pill text-primary px-4 py-1 btn-lg">Log in</a> : <label className="text-primary px-4 py-1">Welcome, <b><u>{globalVals.userName}</u></b></label>) : null)
      }
    </div>
  );
};

export default Header;
