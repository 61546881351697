// import React, { useState, useContext } from 'react';
import React from 'react';
import "../../index.css";
import banner from "../../assets/banner.png";
import styles from "./Home.module.css";
import globalVals from '../../globalVals';
// import { customerContext } from '../../http/axios';
// import { Bars } from 'react-loader-spinner';
import { decryptData } from '../../http/encrypt';

import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";
import StepThree from "./steps/StepThree";
import WelcomeHome from "../WelcomeHome";

const Home = () => {
  // const { user, sendContactMail } = useContext(customerContext);
  // const { user } = useContext(customerContext);
  const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

  // const [clientName, setClientName] = useState("");
  // const [clientMail, setClientMail] = useState("");
  // const [clientMsg, setClientMsg] = useState("");
  // const { error, isLoading } = user;

  // const handleSubmit = (e) => {
  //   e.preventDefault();

    // let tempFormData = {
    //   name: clientName,
    //   email: clientMail,
    //   message: clientMsg
    // };

    // sendContactMail(JSON.stringify(tempFormData));
  // }

  switch (parseInt(session.webStepNum)) {
    case 1:
      globalVals.currentStep = "stepOneForm";
      break;
    case 2:
      globalVals.currentStep = "stepTwoForm";
      break;
    default:
      globalVals.currentStep = "stepThreeForm"
      if (session.webStepNum > 6) {
        globalVals.currentStep = "welcomeBack";
      }
      break;
  }

  return (
    <div>
      <div className="container mb-5 pb-1">
        <img src={banner} className="img-fluid" alt="People leading a happy life" />

        {
          (
            !((globalVals.loggedIn && globalVals.userName)) ?
              <>
                <div className="text-center px-1 px-md-5 my-5 pt-5">
                  <h1 className={styles.heading}><strong>we ask because we care</strong></h1>
                  <h4 className="fw-light w-75 mx-auto">Every detail helps us bring your dream life closer to reality. And the more we know about you and your family, the more we can tailor life to your preferences and aspirations</h4>
                  <h5 className="colorGold mx-5 mt-5 fw-light w-75 mx-auto">Appreciate if you could help us to know more about you to ground every single detail to your preference. Each section won't take more than 1 minute, and you can also fill the information according to your preferred timings</h5>
                  <br /><br />
                  <h2 className={`${styles.heading} my-5`}><strong>Hello! <br /> let's build a life.</strong></h2>
                  <h5 className="fw-light mx-auto w-75 fst-italic">*Our data collection process adheres to the highest levels of privacy and security. Your information is collected solely for the purpose of serving you and will never be shared with third parties.</h5>
                  <br /><br />
                  <h2 className={`${styles.heading}`}><strong>Experience Happiness</strong></h2>
                </div>
              </>
              :
              ((globalVals.loggedIn && globalVals.userName && globalVals.currentStep === "stepOneForm") ?
                <StepOne />
                :
                ((globalVals.loggedIn && globalVals.userName && globalVals.currentStep === "stepTwoForm") ?
                  <StepTwo />
                  :
                  ((globalVals.loggedIn && globalVals.userName && globalVals.currentStep === "stepThreeForm") ?
                    <StepThree />
                    :
                    ((globalVals.loggedIn && globalVals.userName && globalVals.currentStep === "welcomeBack") ?
                      <WelcomeHome />
                      :
                      <>
                        <div className="text-center px-1 px-md-5 my-5 pt-5">
                          <h1 className={styles.heading}><strong>we ask because we care</strong></h1>
                          <h4 className="fw-light w-75 mx-auto">Every detail helps us bring your dream life closer to reality. And the more we know about you and your family, the more we can tailor life to your preferences and aspirations</h4>
                          <h5 className="colorGold mx-5 mt-5 fw-light w-75 mx-auto">Appreciate if you could help us to know more about you to ground every single detail to your preference. Each section won't take more than 1 minute, and you can also fill the information according to your preferred timings</h5>
                          <br /><br />
                          <h2 className={`${styles.heading} my-5`}><strong>Hello! <br /> let's build a life.</strong></h2>
                          <h5 className="fw-light mx-auto w-75 fst-italic">*Our data collection process adheres to the highest levels of privacy and security. Your information is collected solely for the purpose of serving you and will never be shared with third parties.</h5>
                          <br /><br />
                          <h2 className={`${styles.heading}`}><strong>Experience Happiness</strong></h2>
                        </div>
                      </>
                    )
                  )
                )
              )
          )
        }

      </div>

      {/* <div className="container-fluid main-color p-md-5">
        <div className="row">
          <div className="col-md-7 col-12 ms-md-5 mb-5 mb-md-0  position-relative">
            <div className="p-2">
              <h2 className={`${styles.heading} text-white mb-10`}><strong>contact us</strong></h2>
              <div className={styles.line}></div>
              <div className="">
                <div className="d-flex flex-row flex-start align-items-center gap-3">
                  <i className="bi bi-telephone-fill fs-1"></i>
                  <a href="tel:16201" className="text-decoration-none text-white"><h3>16201</h3></a>
                </div>
                <div className="d-flex flex-row  flex-start align-items-center gap-3">
                  <i className="bi bi-envelope-fill fs-1"></i>
                  <a href="mailto:info@mountainview.com" className="text-decoration-none"><h3 className="text-white">info@mountainview.com</h3></a>
                </div>
                <div className="d-flex flex-row flex-start align-items-center gap-3">
                  <a href="https://www.facebook.com/MountainViewEgypt/" className="text-decoration-none text-white"><i className="bi bi-facebook fs-1"></i></a>
                  <a href="https://www.instagram.com/mountainview_egypt/?hl=en" className="text-decoration-none text-white"><i className="bi bi-instagram fs-1"></i></a>
                  <h3>@mountainviewegypt</h3>
                </div>
              </div>
            </div>
            <p className={`d-none d-lg-block ${styles["v-line"]}`}></p>
          </div>
          <div className="col-md-4 col-12 d-flex flex-column mx-md-auto mx-sm-auto position-relative">
            {
              isLoading && (
                <Bars color="#FFF" ariaLabel="bars-loading" wrapperClass="loadingBarsContact" visible={true} />
              )
            }
            <form className="p-2" method='post' onSubmit={handleSubmit}>
              <h5 className="mb-2"><b>Send us a message:</b></h5>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Name *</label>
                <input type="text" className="form-control" id="name" name="name" autoComplete="false" required value={clientName} onChange={(e) => setClientName(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email *</label>
                <input type="email" name="email" className="form-control" id="email" autoComplete="false" required value={clientMail} onChange={(e) => setClientMail(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">Message *</label>
                <textarea name="message" id="message" className="w-100" rows="5" autoComplete="false" required value={clientMsg} onChange={(e) => setClientMsg(e.target.value)}></textarea>
              </div>
              <button className="w-100 w-sm-25 rounded-pill contactSubmit text-white py-1 fw-light fs-4" type="submit"><b>Submit</b></button>
            </form>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
