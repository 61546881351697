import { useLocation } from 'react-router-dom';
import React from 'react';
import logo from "../assets/MVLogo.png";
import logoWhite from "../assets/MVLogo-white.png";

const Footer = () => {
  const locationInfo = useLocation().pathname;

  if (locationInfo === "/") {
    return (
      <div className="container-fluid px-5 py-4 footer-home d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="d-flex flex-md-row flex-column justify-content-center align-items-center">
          <img src={logoWhite} width="200px" className="img-fluid" alt="Mountain View logo" />
          <p className="d-none d-md-block f-line mx-4"></p>
          <h4 className="text-white fw-light">Experience <br /> Happiness</h4>
        </div>
        <div className="d-flex flex-column text-white align-items-md-end align-items-center justify-content-end">
          <p className="m-0">&#169; 2022 - {new Date().getFullYear()}</p>
          <p className="m-0">All rights are reserved to Mountain View</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container-fluid px-5 p-3 footer-border mt-1 d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="d-flex flex-md-row flex-column justify-content-center align-items-center">
          <img src={logo} width="200px" className="img-fluid" alt="Mountain View logo" />
          <p className="d-none d-md-block f-line mx-4"></p>
          <h4 className="mainColor fw-light">Experience <br /> Happiness</h4>
        </div>
        <div className="d-flex flex-column mainColor align-items-md-end align-items-center justify-content-end">
          <p className="m-0">&#169; 2022 - {new Date().getFullYear()}</p>
          <p className="m-0">All rights are reserved to Mountain View</p>
        </div>
      </div>
    );
  }
};

export default Footer;
