import React, { useEffect, useState, useContext } from 'react';
import { customerContext } from '../../../http/axios';
import globalVals from '../../../globalVals';
import { encryptData, decryptData } from '../../../http/encrypt';
import Multiselect from 'multiselect-react-dropdown';

const SpouseInfo = () => {

  const FavSportOptions = globalVals.FavSportOptions;

  const InterestHobbiesOptions = globalVals.InterestHobbiesOptions;

  const { user, getSpuseInfo, setSpuseInfo } = useContext(customerContext);
  const [showError, setShowError] = useState(false);
  let stepToGo = "spouseInfo";

  const { error, stepToTake } = user;

  if (stepToTake !== undefined) {
    stepToGo = stepToTake;
  }

  const [specialAssComm, setSpecialAssComm] = useState(false);
  const [years, setYears] = useState([]);

  const [spouseRowID, setspouseRowID] = useState("");
  const [spouseID, setSpouseID] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const [spouseGender, setSpouseGender] = useState("");
  const [dobMonth, setdobMonth] = useState("");
  const [dobYear, setdobYear] = useState("");
  const [dobDay, setdobDay] = useState("");
  const [idType, setIdType] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [nationality, setNationality] = useState("");
  const [mobileCountry, setMobileCountry] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [compName, setCompName] = useState("");
  const [sassistance, setasssistance] = useState("");
  const [sassspecify, setsassspecify] = useState("");
  const [sports, setSports] = useState([]);
  const [Hobbies, setHobbies] = useState([]);

  const [allNationalities, setallNationalities] = useState([]);
  const [allCountryCodes, setallCountryCodes] = useState([]);
  const [sassspecifyOptions, setSassspecifyOptions] = useState([]);
  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [egypLength, setegypLength] = useState(false);

  const [ShowCompany, setShowCompany] = useState(false);

  useEffect(() => {
    updateSpuseInfo();

    let tempYears = [];
    let cYear = new Date().getFullYear();
    for (let y = 1900; y <= cYear; y++) {
      tempYears.push(<option key={y} value={y}>{y}</option>);
    }
    setYears(tempYears);

    let tempNati = [];
    let tempCodes = [];
    globalVals.countries.map((i, k) => {
      tempNati.push(<option key={k} value={i.isocode}>{i.nationality}</option>);
      tempCodes.push(<option key={k} value={i.code}>{i.name} (+{i.code})</option>);
    });
    setallNationalities(tempNati);
    setallCountryCodes(tempCodes);

    let tempsassspecifyOptions = [];
    globalVals.disabilitiesOptions.map((i, k) => {
      tempsassspecifyOptions.push(<option key={k} value={i.disability}>{i.disability}</option>);
    });
    setSassspecifyOptions(tempsassspecifyOptions);

    let tempJobTitle = [];
    globalVals.jobTitlesOptions.map((i, k) => {
      tempJobTitle.push(<option key={k} value={i.jobTitle}>{i.jobTitle}</option>);
    });
    setJobTitleOptions(tempJobTitle);
  }, []);

  const updateSpuseInfo = () => getSpuseInfo().then((responseData) => {

    setspouseRowID(responseData.spouseRowID);
    setSpouseID(responseData.SpouseID);
    setSpouseName(responseData.SpouseName);
    setSpouseGender(responseData.SpouseGender);
    setdobMonth(String(responseData.BirthDate).split("-")[1] || 1);
    setdobYear(String(responseData.BirthDate).split("-")[2] || 1);
    setdobDay(String(responseData.BirthDate).split("-")[0] || 1900);
    setIdType(responseData.IdType);
    setIdNumber(responseData.IdNumber);
    setNationality(responseData.Nationality);
    setMobileCountry(responseData.CountryCode);
    setMobile(responseData.Mobile);
    setEmail(responseData.Email);
    setJobTitle(responseData.JobTitle);
    setCompName(responseData.CompName);
    setasssistance(responseData.Sassistance);
    setsassspecify(responseData.Sassspecify);
    setSports(responseData.Sports);
    setHobbies(responseData.Hobbies);

    if (responseData.Sassistance === "Y") {
      setSpecialAssComm(true);
    } else {
      setSpecialAssComm(false);
      setsassspecify("");
      setasssistance("N");
    }

    if (responseData.CountryCode == "20") {
      setegypLength(true);
    } else {
      setegypLength(false);
    }

    if (responseData.JobTitle === "House wife" || responseData.JobTitle === "Retired") {
      setShowCompany(false);
      setCompName("");
    } else {
      setShowCompany(true);
    }
  });

  const preventEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let tempFormData = {
      spouseRowID: spouseRowID,
      spouseID: spouseID,
      spouseName: spouseName,
      spouseGender: spouseGender,
      dobMonth: dobMonth,
      dobYear: dobYear,
      dobDay: dobDay,
      idType: idType,
      idNumber: idNumber,
      nationality: nationality,
      mobileCountry: mobileCountry,
      mobile: mobile,
      email: email,
      jobTitle: jobTitle,
      compName: compName,
      sassistance: sassistance,
      sassspecify: sassspecify,
      spouseSports: sports,
      spouseHobbies: Hobbies
    };

    if (stepToGo === "spouseInfo") {
      setSpuseInfo(JSON.stringify(tempFormData)).then((respsonse) => {
        if (globalVals.currentStep === "parentsInfo" && respsonse) {
          const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

          const newSession = {
            ...session,
            currentStep: "parentsInfo",
          }
          globalVals.currentStep = "parentsInfo";
          sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));
          return;
        } if (globalVals.currentStep === "childsInfo" && respsonse) {
          const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

          const newSession = {
            ...session,
            currentStep: "childsInfo",
          }
          globalVals.currentStep = "childsInfo";
          sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));
          return;
        } else {
          setShowError(true);
          setTimeout(() => setShowError(false), 8000);
        }
      });
    }
    if (error) {
      setShowError(true);
      setTimeout(() => setShowError(false), 8000);
    }
  }
  return (
    <>
      <label className="btn-outline">Spouse</label>

      <div className="row mb- position-relative">
        <form className="p-0" onSubmit={handleSubmit} onKeyDown={preventEnter}>
          <div className="container mb-5">
            <div className="row">
              <h5 className=""><b>Personal Data</b></h5>
            </div>
            <div className="row g-3 g-md-5 mb-3 mb-md-5">
              <div className="col-md-6">
                <label htmlFor="fullNameSpouse" className="form-label"> Full Name as on ID * </label>
                <input type="text" className="form-control Input" id="fullNameSpouse" required value={spouseName} onChange={(e) => setSpouseName(e.target.value)} />
              </div>
              <div className="col-md-6">
                <p>Gender *</p>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" required id="male" value="MALE" onChange={(e) => setSpouseGender(e.target.value)} checked={((spouseGender === "MALE") ? true : false)} />
                  <label className="form-check-label" htmlFor="male"> Male </label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" required id="female" value="FEMALE" onChange={(e) => setSpouseGender(e.target.value)} checked={((spouseGender === "FEMALE") ? true : false)} />
                  <label className="form-check-label" htmlFor="female"> Female </label>
                </div>
              </div>
              <div className="col-md-6">
                <p> Choose one from the following and fill it in the next box * </p>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="idpassport" id="idSpouse" value="ID_NUMBER" required onChange={(e) => setIdType(e.target.value)} checked={((idType === "ID_NUMBER") ? true : false)} />
                  <label className="form-check-label" htmlFor="idSpouse"> ID </label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="idpassport" id="passportSpouse" value="PASSPORT" required onChange={(e) => setIdType(e.target.value)} checked={((idType === "PASSPORT") ? true : false)} />
                  <label className="form-check-label" htmlFor="passportSpouse"> Passport </label>
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="IDSSpouse" className="form-label"> ID / Passport * </label>
                <input type="text" className="form-control" id="IDSSpouse" required value={idNumber} onChange={(e) => setIdNumber(e.target.value)} />
              </div>
              <div className="col-md-6">
                <label htmlFor="spouseNationality" className="mb-2"> Nationality * </label>
                <div className="d-flex">
                  <select className="w-100 px-2" id="spouseNationality" required value={nationality} onChange={(e) => setNationality(e.target.value)}>
                    <option>Choose</option>
                    {allNationalities}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <label className="mb-2" id='dob_day'>Date of birth*</label>
                <div className="d-flex gap-2">
                  <select className="w-auto px-2" id="dob_day" name='dob_day' required value={dobDay} onChange={(e) => setdobDay(e.target.value)} >
                    <option key={1} value>Day</option>
                    <option key={2} value="01">1</option>
                    <option key={3} value="02">2</option>
                    <option key={4} value="03">3</option>
                    <option key={5} value="04">4</option>
                    <option key={6} value="05">5</option>
                    <option key={7} value="06">6</option>
                    <option key={8} value="07">7</option>
                    <option key={9} value="08">8</option>
                    <option key={10} value="09">9</option>
                    <option key={11} value="10">10</option>
                    <option key={12} value="11">11</option>
                    <option key={13} value="12">12</option>
                    <option key={14} value="13">13</option>
                    <option key={15} value="14">14</option>
                    <option key={16} value="15">15</option>
                    <option key={17} value="16">16</option>
                    <option key={18} value="17">17</option>
                    <option key={19} value="18">18</option>
                    <option key={20} value="19">19</option>
                    <option key={21} value="20">20</option>
                    <option key={22} value="21">21</option>
                    <option key={23} value="22">22</option>
                    <option key={24} value="23">23</option>
                    <option key={25} value="24">24</option>
                    <option key={26} value="25">25</option>
                    <option key={27} value="26">26</option>
                    <option key={28} value="27">27</option>
                    <option key={29} value="28">28</option>
                    <option key={30} value="29">29</option>
                    <option key={31} value="30">30</option>
                    <option key={32} value="31">31</option>
                  </select>
                  <select className="w-auto px-2" id="dob_month" name='dob_montg' required value={dobMonth} onChange={(e) => setdobMonth(e.target.value)} >
                    <option key={1} value>Month</option>
                    <option key={2} value="01">Jan.</option>
                    <option key={3} value="02">Feb.</option>
                    <option key={4} value="03">Mar.</option>
                    <option key={5} value="04">Apr.</option>
                    <option key={6} value="05">May.</option>
                    <option key={7} value="06">Jun.</option>
                    <option key={8} value="07">Jul.</option>
                    <option key={9} value="08">Aug.</option>
                    <option key={10} value="09">Sept.</option>
                    <option key={11} value="10">Oct.</option>
                    <option key={12} value="11">Nov.</option>
                    <option key={13} value="12">Dec.</option>
                  </select>
                  <select className="w-auto px-2" id="dob_year" name='dob_year' required value={dobYear} onChange={(e) => setdobYear(e.target.value)} >
                    <option key={1} value>Year</option>
                    {years}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <h5 className=""><b>Communication</b></h5>
            </div>
            <div className="row g-3 g-md-5 mb-3 mb-md-5">
              <div className="col-md-6">
                <label htmlFor="spouseMobile" className="m-1"> Contact Mobile * </label>
                <div className="d-flex gap-2">
                  <select className="w-25 px-2" id="spouseMobileCountry" required value={mobileCountry} onChange={(e) => {
                    if (e.target.value == "20") {
                      setegypLength(true);
                    } else {
                      setegypLength(false);
                    }
                    setMobileCountry(e.target.value)
                  }
                  } ><option key={1} value>Select Country Code</option>
                    {allCountryCodes}
                  </select>
                  {
                    egypLength ?
                      <input type="text" className="form-control" id="spouseMobile" required value={mobile} minLength={10} maxLength={10} onChange={(e) => setMobile(e.target.value)} />
                      :
                      <input type="text" className="form-control" id="spouseMobile" required value={mobile} onChange={(e) => setMobile(e.target.value)} />
                  }
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="emailSpouse" className="form-label"> Email </label>
                <input type="email" pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$' className="form-control" placeholder='exmaple@example.com' id="emailSpouse" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
            </div>
            <div className="row">
              <h5 className=""><b>Proficiency</b></h5>
            </div>
            <div className="row g-3 g-md-5 mb-3 mb-md-5">
              <div className="col-md-6">
                <label htmlFor="spouseJobTitle" className="mb-2"> Job Title * </label>
                <div className="d-flex">
                  <select className="w-100 px-2" id="spouseJobTitle" required value={jobTitle} onChange={(e) => {
                    if (e.target.value === "House wife" || e.target.value === "Retired") {
                      setShowCompany(false);
                      setCompName("");
                    } else {
                      setShowCompany(true);
                    }

                    setJobTitle(e.target.value);
                  }
                  }>
                    <option key={1} value="-">Select Job Title</option>
                    {jobTitleOptions}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                {
                  ShowCompany &&
                  <>
                    <label htmlFor="companyNameSpouse" className="form-label"> Company Name * </label>
                    <input type="text" className="form-control" id="companyNameSpouse" required value={compName} onChange={(e) => setCompName(e.target.value)} />
                  </>
                }
              </div>
            </div>
            <div className="row">
              <h5 className=""><b>Community</b></h5>
            </div>
            <div className="row g-3 g-md-5 mb-3 mb-md-5">
              <div className="col-md-6">
                <label htmlFor="dropdownSpouse" className="mb-2"> Favorite Sports (Select one or more) </label>
                <div className="dropdown w-100" id="dropdownSpouse">

                  <Multiselect
                    options={FavSportOptions}
                    selectedValues={sports}
                    onSelect={(values) => setSports(values)}
                    onRemove={(values) => setSports(values)}
                    displayValue="sport"
                  />

                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="spouseHobbies" className="mb-2"> Interests and Hobbies * </label>
                <div className="dropdown w-100" id="spouseHobbies'">

                  <Multiselect
                    options={InterestHobbiesOptions}
                    selectedValues={Hobbies}
                    onSelect={(values) => setHobbies(values)}
                    onRemove={(values) => setHobbies(values)}
                    displayValue="intHob"
                  />

                </div>
              </div>
              <div className="col-md-6">
                <p>Any special impairment that needs special assistance *</p>
                <div className="d-flex w-100 gap-5">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="assistance" id="yes" value="Y" onChange={(e) => { setSpecialAssComm(true); setasssistance(e.target.value); }} checked={((specialAssComm) ? true : false)} />
                    <label className="form-check-label" htmlFor="yes">Yes</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="assistance" id="no" value="B" onChange={(e) => { setsassspecify(""); setSpecialAssComm(false); setasssistance(e.target.value) }} checked={((!specialAssComm) ? true : false)} />
                    <label className="form-check-label" htmlFor="no">No</label>
                  </div>
                </div>
              </div>
              {specialAssComm &&
                <div className="col-md-6">
                  <label htmlFor="specifySpouse" className="form-label"> If yes, please specify * </label>
                  <select className="w-100 px-2" id="specifySpouse" name='specifySpouse' value={sassspecify} onChange={(e) => setsassspecify(e.target.value)} required={true}>
                    <option key={1} value>Select Disability</option>
                    {sassspecifyOptions}
                  </select>
                </div>
              }
            </div>

            <div className="row">
              <div className="submit d-flex justify-content-end mb-1 mb-md-5">
                <button type='submit' className="rounded-pill border-0 main-color px-3 px-md-5 text-white fw-bold py-2">Submit & Continue <i className="bi bi-arrow-right"></i></button>
              </div>
            </div>
          </div>
        </form >
      </div >
    </>
  );
};

export default SpouseInfo;