import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { CustomerProvider } from './http/axios'
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import './assets/fonts/Poppins-Light.ttf';
import './assets/fonts/Poppins-Medium.ttf';
import './assets/fonts/Poppins-SemiBold.ttf';
import "./index.css";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <CustomerProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </CustomerProvider>
  // </React.StrictMode>
);
