import { Button } from "react-bootstrap";

const StepOne = () => {

  return (
    <div className="text-center mt-5 pt-5">
      <div className="row mb-5 justify-content-center">
        <div className="col-12">
          <h2 className="heading text-capitalize text-center mb-1"><strong>Pleasure to meet you</strong></h2>
          <br/>
          <h4 className="fw-light mx-auto w-75 fst-italic">Fruitful relationships grow from genuine care and interest;<br/>and we're interested in getting to know you to tailor our services to your taste.</h4>
          <br/>
          <Button onClick={() => window.location.href = "/pleasure-to-meet-you"} className="rounded-pill main-color px-5 text-white fw-bold mb-3 mb-md-5"><h5 className="mb-0"><b>Go <i className="bi bi-arrow-right"></i></b></h5></Button>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
