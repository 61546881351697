import { useEffect, useState } from "react";
import Multiselect from 'multiselect-react-dropdown';
import globalVals from "../../../globalVals";

export default function JuniorsForm(props) {
  const formNum = parseInt(props.formNumber);
  const formIden = "child_" + formNum;

  const FavSportOptions = globalVals.FavSportOptions;
  const InterestHobbiesOptions = globalVals.InterestHobbiesOptions;

  const [juniorFinal, setJuniorFinal] = useState(props.juniorsAllData[formIden]);

  const [showIdInout, setshowIdInout] = useState(false);
  const [specialAssComm, setSpecialAssComm] = useState(false);
  const [years, setYears] = useState([]);

  useEffect(() => props.juniorsSetData({ ...props.juniorsAllData, [formIden]: juniorFinal }), [juniorFinal]);

  const [allNationalities, setallNationalities] = useState([]);
  const [allCountryCodes, setallCountryCodes] = useState([]);
  const [sassspecifyOptions, setSassspecifyOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [egypLength, setegypLength] = useState(false);

  const [showSchoolData, setShowSchoolData] = useState(false);
  const [educType, setEducType] = useState("");
  const [schoolData, setSchoolData] = useState(false);
  const [universityData, setUniversityData] = useState(false);

  useEffect(() => {
    if (juniorFinal.csassintance_ == "Y") {
      setSpecialAssComm(true);
    } else {
      setSpecialAssComm(false);
    }
    if (juniorFinal.cidType_ != "" && juniorFinal.cidType_ != null) {
      setshowIdInout(true);
    } else {
      setshowIdInout(false);
    }

    let tempYears = [];
    let cYear = new Date().getFullYear();
    for (let y = 1900; y <= cYear; y++) {
      tempYears.push(<option key={y} value={y}>{y}</option>);
    }

    let tempNati = [];
    let tempCodes = [];
    globalVals.countries.map((i, k) => {
      tempNati.push(<option key={k} value={i.isocode}>{i.nationality}</option>);
      tempCodes.push(<option key={k} value={i.code}>{i.name} (+{i.code})</option>);
    });
    setallNationalities(tempNati);
    setallCountryCodes(tempCodes);
    setYears(tempYears);

    let tempschoolNames = [];
    let kCount = 1;
    globalVals.schoolNameOptions.map((i, k) => {
      tempschoolNames.push(<option key={k + kCount} value={i.school}>{i.school}</option>);
      kCount++;
    });
    setSchoolData(tempschoolNames);
    let tempUniversityNames = [];
    globalVals.universitiesNameOptions.map((i, k) => {
      tempUniversityNames.push(<option key={k + kCount} value={i.university}>{i.university}</option>);
    });
    setUniversityData(tempUniversityNames);

    if (juniorFinal.cschoolName_ !== null && juniorFinal.cschoolName_ != "Graduate" && juniorFinal.cschoolName_.length > 0) {
      if (globalVals.schoolNameOptions.find(school => school.school === juniorFinal.cschoolName_)) {
        setJuniorFinal({ ...juniorFinal, ceducationStage_: "School" })
        setEducType("School");
        setSchoolOptions(tempschoolNames);
      } else if (globalVals.universitiesNameOptions.find(university => university.university === juniorFinal.cschoolName_)) {
        setJuniorFinal({ ...juniorFinal, ceducationStage_: "University" });
        setEducType("University");
        setSchoolOptions(tempUniversityNames);
      }
      setShowSchoolData(true);
    } else {
      if (juniorFinal.cschoolName_ != "Graduate") {
        setJuniorFinal({ ...juniorFinal, ceducationStage_: "Graduate" });
      } else {
        setJuniorFinal({ ...juniorFinal, ceducationStage_: "" });
      }
      setShowSchoolData(false);
    }

    let tempsassspecifyOptions = [];
    globalVals.disabilitiesOptions.map((i, k) => {
      tempsassspecifyOptions.push(<option key={k} value={i.disability}>{i.disability}</option>);
    });
    setSassspecifyOptions(tempsassspecifyOptions);
  }, []);

  return (
    <>
      <div>
        <div className="container mb-5">
          <div className="row">
            <span className="mainColor text-decoration-none"> Child #{formNum} </span>
            <h5 className=""><b>Personal Data</b></h5>
          </div>
          <div className="row g-3 g-md-5 mb-3 mb-md-5">
            <div className="col-md-12">
              <label htmlFor={"fullNameID_" + formNum} className="form-label"> Full Name as on ID * </label>
              <input type="text" className="form-control" id={"fullNameID_" + formNum} value={juniorFinal.cname_} name={"fullNameID_" + formNum} required={true} onChange={(e) => setJuniorFinal({ ...juniorFinal, cname_: e.target.value })} />
            </div>
            <div className="col-md-6">
              <p><label htmlFor={"cidType_" + formNum} className="form-label"> ID Type (if applicable) </label></p>
              <div className="form-check form-check-inline" id={"cidType_" + formNum}>
                <input className="form-check-input" type="radio" value={""} id={"cidTypI_" + formNum} name={"cidType_" + formNum} onChange={(e) => { setshowIdInout(false); setJuniorFinal({ ...juniorFinal, cidType_: e.target.value }) }} checked={((juniorFinal.cidType_ === "" || juniorFinal.cidType_ == null) ? true : false)} />
                <label className="form-check-label" htmlFor={"cidTypI_" + formNum}> None </label>
              </div>
              <div className="form-check form-check-inline" id={"cidType_" + formNum}>
                <input className="form-check-input" type="radio" value={"ID_NUMBER"} id={"cidTypI_" + formNum} name={"cidType_" + formNum} onChange={(e) => { setshowIdInout(true); setJuniorFinal({ ...juniorFinal, cidType_: e.target.value }) }} checked={((juniorFinal.cidType_ === "ID_NUMBER") ? true : false)} />
                <label className="form-check-label" htmlFor={"cidTypI_" + formNum}> ID </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" value={"PASSPORT"} id={"cidTypeP_" + formNum} name={"cidType_" + formNum} onChange={(e) => { setshowIdInout(true); setJuniorFinal({ ...juniorFinal, cidType_: e.target.value }) }} checked={((juniorFinal.cidType_ === "PASSPORT") ? true : false)} />
                <label className="form-check-label" htmlFor={"cidTypeP_" + formNum}> Passport </label>
              </div>
            </div>
            <div className="col-md-6">
              {showIdInout &&
                <>
                  <label htmlFor={"childID_" + formNum} className="form-label"> ID / Passport </label>
                  <input type="text" className="form-control" id={"childID_" + formNum} name={"childID_" + formNum} onChange={(e) => setJuniorFinal({ ...juniorFinal, cid_: e.target.value })} value={juniorFinal.cid_} required={true} />
                </>
              }
            </div>
            <div className="col-md-6">
              <p>Gender *</p>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" required={true} id={"male_" + formNum} value="MALE" onChange={(e) => setJuniorFinal({ ...juniorFinal, cgender_: e.target.value })} checked={((juniorFinal.cgender_ === "MALE") ? true : false)} />
                <label className="form-check-label" htmlFor={"male_" + formNum}> Male </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" required={true} id={"female_" + formNum} value="FEMALE" onChange={(e) => setJuniorFinal({ ...juniorFinal, cgender_: e.target.value })} checked={((juniorFinal.cgender_ === "FEMALE") ? true : false)} />
                <label className="form-check-label" htmlFor={"female_" + formNum}> Female </label>
              </div>
            </div>
            <div className="col-md-6">
              <label className="mb-2" id={"dob_day_" + formNum}>Date of birth*</label>
              <div className="d-flex gap-2">
                <select className="w-auto px-2" id={"dob_day_" + formNum} name='dob_day' required={true} value={juniorFinal.cdobDay_} onChange={(e) => setJuniorFinal({ ...juniorFinal, cdobDay_: e.target.value })} >
                  <option key={1} value>Day</option>
                  <option key={2} value="01">1</option>
                  <option key={3} value="02">2</option>
                  <option key={4} value="03">3</option>
                  <option key={5} value="04">4</option>
                  <option key={6} value="05">5</option>
                  <option key={7} value="06">6</option>
                  <option key={8} value="07">7</option>
                  <option key={9} value="08">8</option>
                  <option key={10} value="09">9</option>
                  <option key={11} value="10">10</option>
                  <option key={12} value="11">11</option>
                  <option key={13} value="12">12</option>
                  <option key={14} value="13">13</option>
                  <option key={15} value="14">14</option>
                  <option key={16} value="15">15</option>
                  <option key={17} value="16">16</option>
                  <option key={18} value="17">17</option>
                  <option key={19} value="18">18</option>
                  <option key={20} value="19">19</option>
                  <option key={21} value="20">20</option>
                  <option key={22} value="21">21</option>
                  <option key={23} value="22">22</option>
                  <option key={24} value="23">23</option>
                  <option key={25} value="24">24</option>
                  <option key={26} value="25">25</option>
                  <option key={27} value="26">26</option>
                  <option key={28} value="27">27</option>
                  <option key={29} value="28">28</option>
                  <option key={30} value="29">29</option>
                  <option key={31} value="30">30</option>
                  <option key={32} value="31">31</option>
                </select>
                <select className="w-auto px-2" id={"dob_month_" + formNum} name='dob_montg' required={true} value={juniorFinal.cdobMonth_} onChange={(e) => setJuniorFinal({ ...juniorFinal, cdobMonth_: e.target.value })} >
                  <option key={1} value>Month</option>
                  <option key={2} value="01">Jan.</option>
                  <option key={3} value="02">Feb.</option>
                  <option key={4} value="03">Mar.</option>
                  <option key={5} value="04">Apr.</option>
                  <option key={6} value="05">May.</option>
                  <option key={7} value="06">Jun.</option>
                  <option key={8} value="07">Jul.</option>
                  <option key={9} value="08">Aug.</option>
                  <option key={10} value="09">Sept.</option>
                  <option key={11} value="10">Oct.</option>
                  <option key={12} value="11">Nov.</option>
                  <option key={13} value="12">Dec.</option>
                </select>
                <select className="w-auto px-2" id={"dob_year_" + formNum} name='dob_year' required={true} value={juniorFinal.cdobYear_} onChange={(e) => setJuniorFinal({ ...juniorFinal, cdobYear_: e.target.value })} >
                  <option key={1} value>Year</option>
                  {years}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor={"nationality_" + formNum} className="form-label"> Nationality * </label>
              <select className="w-100 px-2" required={true} id={"nationality_" + formNum} value={juniorFinal.cnationality_} onChange={(e) => setJuniorFinal({ ...juniorFinal, cnationality_: e.target.value })}>
                <option>Choose</option>
                {allNationalities}
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor={"phoneNumber_" + formNum} className="form-label"> Phone Number </label>
              <div className="d-flex gap-2">
                <select className="w-25 px-2" id="mobile_country" value={juniorFinal.cmobileCountry_} onChange={(e) => {
                  if (e.target.value == "20") {
                    setegypLength(true);
                  } else {
                    setegypLength(false);
                  }
                  setJuniorFinal({ ...juniorFinal, cmobileCountry_: e.target.value });
                }
                } >
                  <option key={1} value>Select Country Code</option>
                  {allCountryCodes}
                </select>
                {
                  egypLength ?
                    <input type="text" className="form-control" id={"phoneNumber_" + formNum} minLength={10} maxLength={10} value={juniorFinal.cphoneNumber_} onChange={(e) => setJuniorFinal({ ...juniorFinal, cphoneNumber_: e.target.value })} />
                    :
                    <input type="text" className="form-control" id={"phoneNumber_" + formNum} value={juniorFinal.cphoneNumber_} onChange={(e) => setJuniorFinal({ ...juniorFinal, cphoneNumber_: e.target.value })} />
                }
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
          <div className="row">
            <h5 className=""><b>Educational</b></h5>
          </div>
          <div className="row g-3 g-md-5 mb-3 mb-md-5">
            <div className="col-md-6">
              <label htmlFor={"ceduType_" + formNum} className="form-label"> Educational Stage * </label>
              <select className="w-100 px-2" name={"ceduType_" + formNum} id={"ceduType_" + formNum} value={juniorFinal.ceducationStage_} onChange={(e) => {
                if (e.target.value === "Graduate" || e.target.value == "") {
                  setShowSchoolData(false);
                  setJuniorFinal({ ...juniorFinal, cschoolGrade_: "Graduate" });
                  setJuniorFinal({ ...juniorFinal, cschoolBus_: "" });
                } else {
                  setShowSchoolData(true);
                  if (e.target.value === "School") {
                    setSchoolOptions(schoolData);
                    setEducType("School");
                  } else {
                    setSchoolOptions(universityData);
                    setEducType("University");
                  }
                }
                setJuniorFinal({ ...juniorFinal, ceducationStage_: e.target.value });
              }
              } required={true}>
                <option key={1} value="">Select Education Stage</option>
                <option key={2} value={"Graduate"}>Graduate</option>
                <option key={3} value={"School"}>School</option>
                <option key={4} value={"University"}>University</option>
              </select>
            </div>
            <div className="col-md-6">
              {showSchoolData &&
                <>
                  <label htmlFor={"schoolName_" + formNum} className="form-label"> {educType} Name * </label>
                  <select className="w-100 px-2" name={"schoolName_" + formNum} id={"schoolName_" + formNum} value={juniorFinal.cschoolName_} onChange={(e) => setJuniorFinal({ ...juniorFinal, cschoolName_: e.target.value })} required={true}>
                    <option key={1} value="">Select {educType}</option>
                    {schoolOptions}
                  </select>
                </>
              }
            </div>
            <div className="col-md-6">
              {showSchoolData &&
                <>
                  <label htmlFor={"grade_" + formNum} className="form-label"> Grade * </label>
                  <input type="text" className="form-control" id={"grade_" + formNum} value={juniorFinal.cschoolGrade_} onChange={(e) => setJuniorFinal({ ...juniorFinal, cschoolGrade_: e.target.value })} />
                </>
              }
            </div>
            <div className="col-md-6">
              {showSchoolData &&
                <>
                  <p>Are they subscribed to a university/school bus? *</p>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id={"school_yes_" + formNum} value="Y" onChange={(e) => setJuniorFinal({ ...juniorFinal, cschoolBus_: e.target.value })} checked={((juniorFinal.cschoolBus_ === "Y") ? true : false)} />
                    <label className="form-check-label" htmlFor={"school_yes_" + formNum}> Yes </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id={"school_no_" + formNum} value="N" onChange={(e) => setJuniorFinal({ ...juniorFinal, cschoolBus_: e.target.value })} checked={((juniorFinal.cschoolBus_ === "N") ? true : false)} />
                    <label className="form-check-label" htmlFor={"school_no_" + formNum}> No </label>
                  </div>
                </>
              }
            </div>
          </div>
          <div className="row">
            <h5 className=""><b>Community</b></h5>
          </div>
          <div className="row g-3 g-md-5 mb-3 mb-md-5">
            <div className="col-md-6">
              <label htmlFor={"childSports_" + formNum} className="mb-2"> Favorite Sports (Select one or more) * </label>
              <div className="dropdown w-100" id={"childSports_" + formNum}>

                <Multiselect
                  options={FavSportOptions}
                  selectedValues={juniorFinal.csports_}
                  required={true}
                  onSelect={(values) => setJuniorFinal({ ...juniorFinal, csports_: values })}
                  onRemove={(values) => setJuniorFinal({ ...juniorFinal, csports_: values })}
                  displayValue="sport"
                />

              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor={"childHobbies_" + formNum} className="mb-2"> Interests and Hobbies * </label>
              <div className="dropdown w-100" id={"childHobbies_" + formNum}>

                <Multiselect
                  options={InterestHobbiesOptions}
                  required={true}
                  selectedValues={juniorFinal.chobbies_}
                  onSelect={(values) => setJuniorFinal({ ...juniorFinal, chobbies_: values })}
                  onRemove={(values) => setJuniorFinal({ ...juniorFinal, chobbies_: values })}
                  displayValue="intHob"
                />

              </div>
            </div>
            <div className="col-md-6">
              <p>Any special impairment that needs special assistance *</p>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name={"syes_" + formNum} id={"syes_" + formNum} value="Y" onChange={(e) => { setSpecialAssComm(true); setJuniorFinal({ ...juniorFinal, csassintance_: e.target.value }) }} checked={((juniorFinal.csassintance_ === "Y") ? true : false)} />
                <label className="form-check-label" htmlFor={"syes_" + formNum}> Yes </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name={"sno_" + formNum} id={"sno_" + formNum} value="N" onChange={(e) => { setJuniorFinal({ ...juniorFinal, csassintanceComm_: "" }); setSpecialAssComm(false); setJuniorFinal({ ...juniorFinal, csassintance_: e.target.value }) }} checked={((juniorFinal.csassintance_ === "N") ? true : false)} />
                <label className="form-check-label" htmlFor={"sno_" + formNum}> No </label>
              </div>
            </div>
            {specialAssComm &&
              <div className="col-md-6">
                <label htmlFor={"sspecify_" + formNum} className="form-label"> If yes please specify * </label>
                <select className="w-100 px-2" id={"sspecify_" + formNum} name={"sspecify_" + formNum} value={juniorFinal.csassintanceComm_} onChange={(e) => setJuniorFinal({ ...juniorFinal, csassintanceComm_: e.target.value })} required={true}>
                  <option key={1} value>Select Disability</option>
                  {sassspecifyOptions}
                </select>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
