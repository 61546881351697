import React, { useEffect, useState, useContext } from 'react';
import { Bars } from 'react-loader-spinner';
import { customerContext } from '../../http/axios';
import globalVals from '../../globalVals';
import { encryptData, decryptData } from '../../http/encrypt';
import "../../index.css";

const GatherInfo = () => {

  const { user, getPersonalInfo, setPersonalInfo } = useContext(customerContext);
  const [showError, setShowError] = useState(false);
  const [years, setYears] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [specialAssComm, setSpecialAssComm] = useState(false);
  let stepToGo = "stepOneForm";

  const { error, stepToTake, isLoading } = user;

  if (stepToTake !== undefined) {
    stepToGo = stepToTake;
  }

  const [nameEnglish, setnameEnglish] = useState("");
  const [IdType, setIdType] = useState("");
  const [IDPassport, setIDPassport] = useState("");
  const [Gender, setGender] = useState("");
  const [dobDay, setdobDay] = useState("");
  const [dobMonth, setdobMonth] = useState("");
  const [dobYear, setdobYear] = useState("");
  const [nationality, setNationality] = useState("");
  const [pemail, setpemail] = useState("");
  const [pphoneNumCountry, setpphoneNumCountry] = useState("");
  const [pphoneNum, setpphoneNum] = useState("");
  const [wCNum, setwCNum] = useState("");
  const [wNum, setwNum] = useState("");
  const [cLandline, setcLandline] = useState("");
  const [landline, setlandline] = useState("");
  const [pJobTitle, setpJobTitle] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [sassistance, setasssistance] = useState("N");
  const [sassspecify, setsassspecify] = useState("");
  const [residence, setresidence] = useState("");
  const [city, setcity] = useState("");
  const [street, setstreet] = useState("");
  const [buildingVilla, setbuildingVilla] = useState("");

  const [allNationalities, setallNationalities] = useState([]);
  const [allCountryCodes, setallCountryCodes] = useState([]);
  const [egypLength, setegypLength] = useState(false);
  const [sassspecifyOptions, setSassspecifyOptions] = useState([]);

  const [ShowCompany, setShowCompany] = useState(false);

  useEffect(() => {
    updateClientInfo();

    let tempYears = [];
    let cYear = new Date().getFullYear();
    for (let y = 1900; y <= cYear; y++) {
      tempYears.push(<option key={y} value={y}>{y}</option>);
    }
    setYears(tempYears);

    let tempNati = [];
    let tempCodes = [];
    globalVals.countries.map((i, k) => {
      tempNati.push(<option key={k} value={i.isocode}>{i.nationality}</option>);
      tempCodes.push(<option key={k} value={i.code}>{i.name} (+{i.code})</option>);
    });
    setallNationalities(tempNati);
    setallCountryCodes(tempCodes);

    let tempJobTitle = [];
    globalVals.jobTitlesOptions.map((i, k) => {
      tempJobTitle.push(<option key={k} value={i.jobTitle}>{i.jobTitle}</option>);
    });
    setJobTitle(tempJobTitle);

    let tempsassspecifyOptions = [];
    globalVals.disabilitiesOptions.map((i, k) => {
      tempsassspecifyOptions.push(<option key={k} value={i.disability}>{i.disability}</option>);
    });
    setSassspecifyOptions(tempsassspecifyOptions);
  }, []);

  const updateClientInfo = () => getPersonalInfo().then((responseData) => {

    let dateOfBirthSplited = responseData.dateOfBirth.toUpperCase();
    dateOfBirthSplited = dateOfBirthSplited.split("-");

    setnameEnglish(responseData.fullName);
    setIdType(responseData.idType);
    setIDPassport(responseData.idNumber);
    setGender(responseData.gender);
    setdobDay(dateOfBirthSplited[0]);
    setdobMonth(dateOfBirthSplited[1]);
    setdobYear(dateOfBirthSplited[2]);
    setNationality(responseData.nationality);
    setpemail(responseData.email);
    setpphoneNumCountry(responseData.phoneNumberCountry);
    setpphoneNum(responseData.phoneNumber);
    setwCNum(responseData.whatsappCountry);
    setwNum(responseData.whatsappNum);
    setcLandline(responseData.landlingCountry);
    setlandline(responseData.landlingNum);
    setpJobTitle(responseData.jobTitle);
    setcompanyName(responseData.companyName);
    setasssistance(responseData.specialNeeds || 'N');
    setsassspecify(responseData.specialNeedsDesc);
    setresidence(globalVals.countries.find(country => country.isocode == responseData.country).name);
    setcity(responseData.city);
    setstreet(responseData.street);
    setbuildingVilla(responseData.building);

    if (responseData.specialNeeds === "Y") {
      setSpecialAssComm(true);
    } else {
      setSpecialAssComm(false);
      setsassspecify("");
    }

    if (responseData.jobTitle === "House wife" || responseData.jobTitle === "Retired") {
      setShowCompany(false);
      setcompanyName("");
    } else {
      setShowCompany(true);
    }
  });

  const preventEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let tempFormData = {
      cName: nameEnglish,
      idType: IdType,
      natID: IDPassport,
      email: pemail,
      Gender: Gender,
      dobDay: dobDay,
      dobMonth: dobMonth,
      dobYear: dobYear,
      nationality: nationality,
      pCNum: pphoneNumCountry,
      pNum: pphoneNum,
      wCNum: wCNum,
      wNum: wNum,
      cLandline: cLandline,
      landline: landline,
      pJobTitle: pJobTitle,
      companyName: companyName,
      sassistance: sassistance,
      sassspecify: sassspecify
    };

    if (stepToGo === "stepOneForm") {
      setPersonalInfo(JSON.stringify(tempFormData)).then((respsonse) => {
        if (globalVals.currentStep === "stepTwoForm" && respsonse) {
          const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

          const newSession = {
            ...session,
            currentStep: "stepTwoForm",
            webStepNum: 2
          };
          sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));

          return window.location.href = "/";
        } else {
          setShowError(true);
          setTimeout(() => setShowError(false), 8000);
        }
      });
    }
    if (error) {
      setShowError(true);
      setTimeout(() => setShowError(false), 8000);
    }
  }

  return (
    <div className="container p-3 p-md-5 position-relative">
      {
        isLoading && (
          <Bars color="#003da7" ariaLabel="bars-loading" wrapperClass="loadingBars" visible={true} />
        )
      }

      <div className="row mb-5">
        <div className="col-12">
          <h4 className='stepHeader'>Step 1/3</h4>
          <h2 className="heading text-capitalize text-start mb-1"><strong>Pleasure to meet you</strong></h2>
          <p className="fw-light h5 text-start">Fruitful relationships grow from genuine care and interest;<br />and we're interested in getting to know you to tailor our services to your taste.</p>
          <br />
          <p className='infoText'>Note: To update any of the contracted information that can’t be edited here, Please contact the call center on <b>16201</b>.</p>
        </div>
      </div>

      <form method='post' className='mt-5 d-flex flex-column' onSubmit={handleSubmit} onKeyDown={preventEnter}>
        <div className="row mb-5">
          <div className="container mb-5">
            <div className="row">
              <h5 className=""><b>Personal</b></h5>
            </div>
            <div className="row g-3 g-md-5">
              <div className="col-12s">
                <label htmlFor="nameEnglish" className="f orm-label">Full Name in English (as on ID) *</label>
                <input type="text" className="form-control" id="nameEnglish" name='nameEnglish' value={nameEnglish} readOnly={true} disabled={true} />
              </div>
              <div className="col-md-6">
                <p>Choose one from the following and fill it in the next box *</p>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="id" readOnly={true} disabled={true} checked={((IdType === "ID_NUMBER") ? true : false)} />
                  <label className="form-check-label" htmlFor="id">ID</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="passport" readOnly={true} disabled={true} checked={((IdType === "PASSPORT") ? true : false)} />
                  <label className="form-check-label" htmlFor="passport">Passport</label>
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="ID_Passport" className="form-label">ID / Passport *</label>
                <input type="text" className="form-control" id="ID_Passport" readOnly={true} disabled={true} value={IDPassport} />
              </div>
              <div className="col-md-6">
                <p>Gender *</p>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="gender" id="male" value="MALE" required={true} onChange={(e) => setGender(e.target.value)} checked={((Gender === "MALE") ? true : false)} />
                  <label className="form-check-label" htmlFor="male">Male</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="gender" id="female" value="FEMALE" required={true} onChange={(e) => setGender(e.target.value)} checked={((Gender === "FEMALE") ? true : false)} />
                  <label className="form-check-label" htmlFor="female">Female</label>
                </div>
              </div>
              <div className="col-md-6">
                <label className="mb-2" htmlFor='dob'>Date of birth*</label>
                <div className="d-flex gap-2" id='dob'>
                  <select className="w-auto px-2" id="dob_day" name='dob_day' required={true} value={dobDay} onChange={(e) => setdobDay(e.target.value)} >
                    <option key={1} value selected>Day</option>
                    <option key={2} value="01">1</option>
                    <option key={3} value="02">2</option>
                    <option key={4} value="03">3</option>
                    <option key={5} value="04">4</option>
                    <option key={6} value="05">5</option>
                    <option key={7} value="06">6</option>
                    <option key={8} value="07">7</option>
                    <option key={9} value="08">8</option>
                    <option key={10} value="09">9</option>
                    <option key={11} value="10">10</option>
                    <option key={12} value="11">11</option>
                    <option key={13} value="12">12</option>
                    <option key={14} value="13">13</option>
                    <option key={15} value="14">14</option>
                    <option key={16} value="15">15</option>
                    <option key={17} value="16">16</option>
                    <option key={18} value="17">17</option>
                    <option key={19} value="18">18</option>
                    <option key={20} value="19">19</option>
                    <option key={21} value="20">20</option>
                    <option key={22} value="21">21</option>
                    <option key={23} value="22">22</option>
                    <option key={24} value="23">23</option>
                    <option key={25} value="24">24</option>
                    <option key={26} value="25">25</option>
                    <option key={27} value="26">26</option>
                    <option key={28} value="27">27</option>
                    <option key={29} value="28">28</option>
                    <option key={30} value="29">29</option>
                    <option key={31} value="30">30</option>
                    <option key={32} value="31">31</option>
                  </select>
                  <select className="w-auto px-2" id="dob_month" name='dob_montg' required={true} value={dobMonth} onChange={(e) => setdobMonth(e.target.value)} >
                    <option key={1} value selected>Month</option>
                    <option key={2} value="JAN">Jan.</option>
                    <option key={3} value="FEB">Feb.</option>
                    <option key={4} value="MAR">Mar.</option>
                    <option key={5} value="APR">Apr.</option>
                    <option key={6} value="MAY">May.</option>
                    <option key={7} value="JUN">Jun.</option>
                    <option key={8} value="JUL">Jul.</option>
                    <option key={9} value="AUG">Aug.</option>
                    <option key={10} value="SEPT">Sept.</option>
                    <option key={11} value="OCT">Oct.</option>
                    <option key={12} value="NOV">Nov.</option>
                    <option key={13} value="DEC">Dec.</option>
                  </select>
                  <select className="w-auto px-2" id="dob_year" name='dob_year' required={true} value={dobYear} onChange={(e) => setdobYear(e.target.value)} >
                    <option key={1} value selected>Year</option>
                    {years}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="nationality" className="mb-2">Nationality *</label>
                <div className="d-flex gap-2">
                  <select className="w-100 px-2" id="nationality" name='dob_year' required={true} value={nationality} onChange={(e) => setNationality(e.target.value)} >
                    <option key={1} value selected>Choose</option>
                    {allNationalities}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="pemail" className="form-label">Email *</label>
                <input type="email" className="form-control" id='pemail' disabled readOnly value={pemail} />
              </div>
              <div className="col-md-6">
                <label htmlFor="pphoneNum" className="m-1">Primary phone number as in your contract *</label>
                <div className="d-flex gap-2">
                  <input type="text" className="form-control" disabled readOnly id="pphoneNum" value={pphoneNum} />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="wNum" className="m-1">Whatsapp Number *</label>
                <div className="d-flex gap-2">
                  <select className="w-25 px-2" id="spouseMobile" required={true} value={wCNum} onChange={(e) => {
                    if (e.target.value == "20") {
                      setegypLength(true);
                    } else {
                      setegypLength(false);
                    }
                    setwCNum(e.target.value)
                  }
                  } >
                    <option key={1} value>Select Country Code</option>
                    {allCountryCodes}
                  </select>
                  {
                    egypLength ?
                      <input type="text" className="form-control" id="wNum" name='wNum' required={true} value={wNum} minLength={10} maxLength={10} onChange={(e) => setwNum(e.target.value)} />
                      :
                      <input type="text" className="form-control" id="wNum" name='wNum' required={true} value={wNum} onChange={(e) => setwNum(e.target.value)} />
                  }
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="landline" className="form-label">Other Contact</label>
                <div className="d-flex gap-2">
                  <select className="w-25 px-2" id="otherCC" name="otherCC" value={cLandline} onChange={(e) => {
                    if (e.target.value == "20") {
                      setegypLength(true);
                    } else {
                      setegypLength(false);
                    }
                    setcLandline(e.target.value)
                  }
                  } >
                    <option key={1} value>Select Country Code</option>
                    {allCountryCodes}
                  </select>
                  {
                    egypLength ?
                      <input type="text" className="form-control" id="landline" name='landline' minLength={10} maxLength={10} value={landline} onChange={(e) => setlandline(e.target.value)} />
                      :
                      <input type="text" className="form-control" id="landline" name='landline' value={landline} onChange={(e) => setlandline(e.target.value)} />
                  }
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="pJobTitle" className="mb-2">Job Title *</label>
                <div className="d-flex gap-2">
                  <select className="w-100 px-2" id="pJobTitle" name='pJobTitle' required={true} value={pJobTitle} onChange={(e) => {
                    if (e.target.value === "House wife" || e.target.value === "Retired") {
                      setShowCompany(false);
                      setcompanyName("");
                    } else {
                      setShowCompany(true);
                    }

                    setpJobTitle(e.target.value);
                  }} >
                    <option key={1} value>Select Job Title</option>
                    {jobTitle}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                {ShowCompany &&
                  <>
                    <label htmlFor="companyName" className="form-label">Company Name *</label>
                    <input type="text" className="form-control" id="companyName" name='companyName' required={true} value={companyName} onChange={(e) => setcompanyName(e.target.value)} />
                  </>
                }
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <p>Any special impairment that needs special assistance *</p>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="assistance" id="yes" value="Y" onChange={(e) => { setSpecialAssComm(true); setasssistance(e.target.value); }} checked={((specialAssComm) ? true : false)} />
                  <label className="form-check-label" htmlFor="yes">Yes</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="assistance" id="no" value="N" onChange={(e) => { setsassspecify(""); setSpecialAssComm(false); setasssistance(e.target.value) }} checked={((!specialAssComm) ? true : false)} />
                  <label className="form-check-label" htmlFor="no">No</label>
                </div>
              </div>
              {specialAssComm &&
                <div className="col-md-6">
                  <label htmlFor="sassspecify" className="form-label">Please specify *</label>
                  <select className="w-100 px-2" id="sassspecify" name='sassspecify' value={sassspecify} onChange={(e) => setsassspecify(e.target.value)} required={true}>
                    <option key={1} value>Select Disability</option>
                    {sassspecifyOptions}
                  </select>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="row mb-1 mb-md-5">
          <div className="container mb-5">
            <div className="row">
              <h5 className=""><b>Address</b></h5>
            </div>
            <div className="row g-3 g-md-5 g-md-5">
              <div className="col-md-6">
                <label htmlFor="country" className="mb-2">Country of residence *</label>
                <div className="d-flex gap-2">
                  <input type="text" className="form-control" id="country" readOnly={true} disabled={true} value={residence} autoComplete='false' />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="city" className="mb-2">City *</label>
                <div className="d-flex gap-2">
                  <input type="text" className="form-control" id="city" readOnly={true} disabled={true} value={city} />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="street" className="form-label">Street *</label>
                <input type="text" className="form-control" id="street" readOnly={true} disabled={true} value={street} />
              </div>
              <div className="col-md-6">
                <label htmlFor="buildingVilla" className="form-label">Building / Villa Number *</label>
                <input type="text" className="form-control" id="buildingVilla" value={buildingVilla} readOnly={true} disabled={true} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="submit d-flex justify-content-end mb-1 mb-md-5">
            <button className="rounded-pill border-0 main-color px-3 px-md-5 text-white fw-bold py-2" type="submit">Submit & Continue <i className="bi bi-arrow-right"></i></button>
          </div>
        </div>
      </form>
    </div >
  );
};

export default GatherInfo;
