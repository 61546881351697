import React, { useContext, useEffect, useState } from 'react'
import { customerContext } from '../../../http/axios';
import { encryptData, decryptData } from '../../../http/encrypt';
import globalVals from '../../../globalVals';
import { Bars } from 'react-loader-spinner';

import * as Icon from 'react-bootstrap-icons';
import { Button, Form } from "react-bootstrap";
import NanniesForm from "./NanniesForm";

const NanniesDrivers = () => {

  const { user, getPersonsInfo, setPersonsData } = useContext(customerContext);
  const [showError, setShowError] = useState(false);
  let stepToGo = "otherInfo";

  const { error, stepToTake } = user;

  if (stepToTake !== undefined) {
    stepToGo = stepToTake;
  }

  const [NanDriversData, setNanDriversData] = useState({});
  const [unitsData, setUnitsData] = useState("");

  const [PersonsDataSet, setPersonsDataSet] = useState(true);
  const [formIds, setFormIDs] = useState([]);
  let formKey = 0;

  useEffect(() => {
    updatePersonsInfo();
  }, []);

  const updatePersonsInfo = () => {
    if (PersonsDataSet) {
      getPersonsInfo().then(responseData => {
        const ObjKeys = Object.keys(responseData.personsInfo);
        if (ObjKeys.length > 0) {
          setNanDriversData(responseData.personsInfo);
        } else {
          handleAddForm();
        }

        setUnitsData(Object.values(responseData.unitsInfo));
      });
      setPersonsDataSet(false);
    }
  };

  useEffect(() => {
    let FormIDss = [];
    Object.keys(NanDriversData).map((k) => FormIDss.push(parseInt(k.replace("person_", ""))));
    if (FormIDss.length == 0) {
      FormIDss.push(1);
    }
    setFormIDs(FormIDss);

  }, [NanDriversData]);

  const handleAddForm = () => {
    let newFormID = (formIds.length + 1);
    setNanDriversData({
      ...NanDriversData, ["person_" + newFormID]: {
        personRowID: "",
        personID: "",
        pname_: "",
        pgender_: "",
        pdobDay_: "",
        pdobMonth_: "",
        pdobYear_: "",
        pidType_: "",
        pidNumber_: "",
        pnationality_: "",
        pmobileCountry_: "",
        pphoneNumber_: "",
        pServiceType: "",
        pServiceDeligated: "",
        pServiceUnit: "",
        csassintance_: "",
        csassintanceComm_: "",
        pservices_: []
      }
    });
  };

  const handleDeleteForm = (formId) => {
    if (formIds.length === 1 || formId === 0) {
      return;
    }
    setFormIDs(formIds.filter((id) => id !== formId));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (stepToGo === "otherInfo") {

      setPersonsData(JSON.stringify(NanDriversData)).then((respsonse) => {
        if (globalVals.currentStep === "welcomeBack" && respsonse) {
          const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

          const newSession = {
            ...session,
            currentStep: "welcomeBack",
          }
          sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));

          return window.location.href = "/welcome-back";
        } else {
          setShowError(true);
          setTimeout(() => setShowError(false), 8000);
        }
      });
    }
    if (error) {
      setShowError(true);
      setTimeout(() => setShowError(false), 8000);
    }
  }

  const handleSkip = (e) => {
    e.preventDefault();
    const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

    const newSession = {
      ...session,
      currentStep: "welcomeBack",
      webStepNum: 7
    }
    sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));

    return window.location.href = "/welcome-back";
  }

  return (
    <>
      <label className="btn-outline mb-3">Other family members</label>
      <br />
      <p className='infoText'>* Please fill in if you have any Household resident staff (i.e Housekeeper, Nany, Driver).</p>

      <form className="p-0 position-relative" method='post' onSubmit={handleSubmit}>
        <div className="row my-5 position-relative">

          {formIds.map((formId) => {
            formKey++;
            if (Object.keys(NanDriversData).length > 0) {
              return (
                <div key={formKey}>
                  {formIds.length > 1 && formId !== 0 && (
                    <div className="text-end ">
                      <button className="btn btn-primary" onClick={() => handleDeleteForm(formId)} ><Icon.Trash3Fill /></button>
                    </div>
                  )}
                  <NanniesForm
                    formNumber={formId}
                    showDelete={formIds.length > 1}
                    personsAllData={NanDriversData}
                    personsSetData={setNanDriversData}
                    unitsData={unitsData}
                  />
                </div>
              )
            } else {
              return <Bars color="#003da7" ariaLabel="bars-loading" wrapperClass="loadingBars h-100 w-100" visible={true} />
            }
          }
          )}

          {formIds.length > 0 && (
            <div className="mb-3 mb-md-5">
              <a onClick={handleAddForm} className="fw-bold text-decoration-none mainColor mx-3 addBtn" role="button">Add Person +</a>
            </div>
          )}

          <div className="row">
            <div className="submit d-flex justify-content-end mb-1 mb-md-5">
              <Button type='button' variant="white" className="rounded-pill px-3 px-md-5 fw-bold mx-3 btnSkip" onClick={handleSkip}>Skip</Button>
              <Button type='submit' className="rounded-pill main-color px-3 px-md-5 text-white fw-bold">Submit <i className="bi bi-arrow-right"></i></Button>
            </div>
          </div>

        </div>
      </form>
    </>
  );
};

export default NanniesDrivers;