import React, { useState, useEffect } from 'react';
import axios from 'axios';
import globalVals from '../globalVals';
import { encryptData, decryptData } from './encrypt';

const customerContext = React.createContext()

const CustomerProvider = ({ children }) => {

    // const baseAPI = 'https://dmgian.corp-dmg.com/_cdc_api/index.php';
    const baseAPI = 'https://cdc.mountainviewegypt.com/__API/index.php';

    const [user, setUser] = useState({
        isLoading: false,
        error: null
    });

    useEffect(() => {
        if (user.error) {
            setTimeout(() => {
                setUser({
                    ...user,
                    error: null,
                });
            }, 1);
        }
    });

    const sendContactMail = async (formData) => {
        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'sendContactUsMessages',
            credentials: {
                formData
            }
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.sendStatus === 'SUCCESS') {
                alert("Your messages has been received by our team, we will contact with you soon.");
            } else {
                alert("Sorry, somethign wrong happened please try again later.");
            }

            setUser({
                isLoading: false
            });
        }).catch(() => {
            alert("Sorry, somethign wrong happened please try again later.");
            setUser({
                isLoading: false
            });
        });
    }

    // const getOTP = async (email, phone) => {
    const getOTP = async (phone) => {

        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'getOTP',
            credentials: {
                // email,
                phone
            }
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.loginStatus === 'SUCCESS') {
                globalVals.currentStep = "otpForm";
                globalVals.accessToken = postResult.accessToken;

                sessionStorage.setItem("CDC_SESSION_ID", encryptData(postResult));

                setUser({
                    stepToTake: "otpForm",
                    isLoading: false,
                    error: false
                });
            } else {
                setUser({
                    stepToTake: "loginForm",
                    isLoading: false,
                    error: true
                });
            }
        }).catch(() => {
            setUser({
                stepToTake: "loginForm",
                isLoading: false,
                error: true
            });
        });
    }

    const getPersonalInfo = async () => {

        let finalCustomerData = {
            fullName: "",
            idType: "",
            idNumber: "",
            gender: "",
            dateOfBirth: "",
            nationality: "",
            email: "",
            phoneNumberCountry: "",
            phoneNumber: "",
            whatsappNum: "",
            landlingNum: "",
            jobTitle: "",
            companyName: "",
            specialNeeds: "",
            specialNeedsDesc: "",
            country: "",
            city: "",
            street: "",
            building: ""
        };

        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'getPersonalInfo',
            user: globalVals.user
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.getStatus === 'SUCCESS') {
                setUser({
                    ...user,
                    isLoading: false,
                    error: false
                });

                finalCustomerData = {
                    fullName: postResult.customerData.fullName || "",
                    idType: postResult.customerData.idType || "",
                    idNumber: postResult.customerData.idNumber || "",
                    gender: postResult.customerData.gender || "",
                    dateOfBirth: postResult.customerData.dateOfBirth || "",
                    nationality: postResult.customerData.nationality || "",
                    email: postResult.customerData.email || "",
                    phoneNumberCountry: postResult.customerData.phoneNumberCountry || "",
                    phoneNumber: postResult.customerData.phoneNumber || "",
                    whatsappCountry: postResult.customerData.whatsappCountry || "",
                    whatsappNum: postResult.customerData.whatsappNum || "",
                    landlingCountry: postResult.customerData.landlingCountry || "",
                    landlingNum: postResult.customerData.landlingNum || "",
                    jobTitle: postResult.customerData.jobTitle || "",
                    companyName: postResult.customerData.companyName || "",
                    specialNeeds: postResult.customerData.specialNeeds || "",
                    specialNeedsDesc: postResult.customerData.specialNeedsDesc || "",
                    country: postResult.customerData.country || "",
                    city: postResult.customerData.city || "",
                    street: postResult.customerData.street || "",
                    building: postResult.customerData.building || ""
                }
            } else {
                setUser({
                    ...user,
                    isLoading: false,
                    error: true
                });
            }
        }).catch(() => {
            setUser({
                ...user,
                isLoading: false,
                error: true
            });
        });

        return finalCustomerData;
    }

    const setPersonalInfo = async (formData) => {
        let insertResponse = false;

        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'setPersonalInfo',
            user: globalVals.user,
            credentials: { formData }
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.insertStatus === 'SUCCESS') {
                globalVals.currentStep = "stepTwoForm";
                insertResponse = true;
                setUser({
                    stepToTake: "stepTwoForm",
                    isLoading: true,
                    error: false
                });
            } else {
                setUser({
                    stepToTake: "stepOneForm",
                    isLoading: false,
                    error: true
                });
            }
        }).catch(() => {
            setUser({
                stepToTake: "stepOneForm",
                isLoading: false,
                error: true
            });
        });

        return insertResponse;
    }

    const getEagerData = async () => {

        let finalEagerData = {
            customerRowID: "",
            PrefLang: "",
            CommEvent: "",
            PrefComm: "",
            MartStat: "",
            HaveKids: "",
            FamilyCount: "",
            CarCount: "",
            HavePets: "",
            PetCount: "",
            sports: [],
            Hobbies: [],
            MemberShip: ""
        };

        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'getEagerData',
            user: globalVals.user
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.getStatus === 'SUCCESS') {
                setUser({
                    ...user,
                    isLoading: false,
                    error: false
                });

                finalEagerData = {
                    customerRowID: postResult.eagerData.customerRowID || "",
                    PrefLang: postResult.eagerData.language || "",
                    CommEvent: postResult.eagerData.community_events || "",
                    PrefComm: postResult.eagerData.comm_method || "",
                    MartStat: postResult.eagerData.marital_status || "",
                    HaveKids: postResult.eagerData.kids_flag || "",
                    FamilyCount: postResult.eagerData.family_count || 0,
                    CarCount: postResult.eagerData.cars_count || 0,
                    CarsData: postResult.eagerData.cars_data || {},
                    HavePets: postResult.eagerData.pets_flag || "",
                    PetCount: postResult.eagerData.pets_count || 0,
                    sports: postResult.eagerData.all_sports || [],
                    Hobbies: postResult.eagerData.interesrts_hobbies || [],
                    MemberShip: postResult.eagerData.clubs || []
                }
            } else {
                setUser({
                    ...user,
                    isLoading: false,
                    error: true
                });
            }
        }).catch(() => {
            setUser({
                ...user,
                isLoading: false,
                error: true
            });
        });

        return finalEagerData;
    }

    const setEagerData = async (formData) => {
        let insertResponse = false;

        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'setEagerInfo',
            user: globalVals.user,
            credentials: { formData }
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.updateStatus === 'SUCCESS') {
                let currentData = JSON.parse(formData);
                if (currentData.clientMartStat === "M") {
                    if (currentData.clientHaveKids === "N") {
                        globalVals.preStep = "parentsInfo";
                    }
                    globalVals.currentStep = "stepThreeForm";
                } else if (currentData.clientHaveKids === "Y") {
                    globalVals.currentStep = "childsInfo";
                } else if (parseInt(currentData.clientFamilyCount) > 1) {
                    globalVals.currentStep = "parentsInfo";
                } else {
                    globalVals.currentStep = "otherInfo";
                }

                setUser({
                    stepToTake: globalVals.currentStep,
                    isLoading: true,
                    error: false
                });
                insertResponse = true;
                return;
            } else {
                setUser({
                    stepToTake: "stepTwoForm",
                    isLoading: false,
                    error: true
                });
            }
        }).catch(() => {
            setUser({
                stepToTake: "stepTwoForm",
                isLoading: false,
                error: true
            });
        });

        return insertResponse;
    }

    const getSpuseInfo = async () => {

        let finalSpouseData = {
            spouseRowID: "",
            SpouseID: "",
            SpouseName: "",
            SpouseGender: "",
            BirthDate: [],
            IdType: "",
            IdNumber: "",
            Nationality: "",
            CountryCode: "",
            Mobile: "",
            Email: "",
            JobTitle: "",
            CompName: "",
            Sassistance: "",
            Sassspecify: "",
            Sports: [],
            Hobbies: []
        };

        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'getSpouseInfo',
            user: globalVals.user
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.getStatus === 'SUCCESS') {
                setUser({
                    ...user,
                    isLoading: false,
                    error: false
                });

                finalSpouseData = {
                    spouseRowID: postResult.spouseInfo.spouse_row_id || "",
                    SpouseID: postResult.spouseInfo.spouse_id || "",
                    SpouseGender: postResult.spouseInfo.spouse_gender || "",
                    SpouseName: postResult.spouseInfo.spouse_name || "",
                    BirthDate: postResult.spouseInfo.birthdate || "",
                    IdType: postResult.spouseInfo.id_type || "",
                    IdNumber: postResult.spouseInfo.national_id || "",
                    Nationality: postResult.spouseInfo.nationality || "",
                    CountryCode: postResult.spouseInfo.country_code || "",
                    Mobile: postResult.spouseInfo.mobile || "",
                    Email: postResult.spouseInfo.email_address || "",
                    JobTitle: postResult.spouseInfo.job_title || "",
                    CompName: postResult.spouseInfo.company_name || "",
                    Sassistance: postResult.spouseInfo.sspecialass || "",
                    Sassspecify: postResult.spouseInfo.sspecialcomment || "",
                    Sports: postResult.spouseInfo.all_sports || [],
                    Hobbies: postResult.spouseInfo.all_hobbies || []
                }
            } else {
                setUser({
                    ...user,
                    isLoading: false,
                    error: true
                });
            }
        }).catch(() => {
            setUser({
                ...user,
                isLoading: false,
                error: true
            });
        });

        return finalSpouseData;
    }

    const setSpuseInfo = async (formData) => {
        let insertResponse = false;

        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'setSpouseInfo',
            user: globalVals.user,
            credentials: { formData }
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.updateStatus === 'SUCCESS') {
                insertResponse = true;
                if (postResult.have_kids === "N") {
                    globalVals.currentStep = "parentsInfo";
                    setUser({
                        stepToTake: "parentsInfo",
                        isLoading: true,
                        error: false
                    });
                } else {
                    globalVals.currentStep = "childsInfo";
                    setUser({
                        stepToTake: "childsInfo",
                        isLoading: true,
                        error: false
                    });
                }
            } else {
                setUser({
                    stepToTake: "spouseInfo",
                    isLoading: false,
                    error: true
                });
            }
        }).catch(() => {
            setUser({
                stepToTake: "spouseInfo",
                isLoading: false,
                error: true
            });
        });

        return insertResponse;
    }

    const getChildsInfo = async () => {

        let fullChildsData = {};

        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'getChildsInfo',
            user: globalVals.user
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.getStatus === 'SUCCESS') {
                setUser({
                    ...user,
                    isLoading: false,
                    error: false
                });
                fullChildsData = postResult.childsInfo;
            } else {
                setUser({
                    ...user,
                    isLoading: false,
                    error: true
                });
            }
        }).catch(() => {
            setUser({
                ...user,
                isLoading: false,
                error: true
            });
        });

        return fullChildsData;
    }

    const setChildsData = async (formData) => {
        let insertResponse = false;

        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'setChildsData',
            user: globalVals.user,
            credentials: { formData }
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.updateStatus === 'SUCCESS') {
                globalVals.currentStep = "parentsInfo";
                insertResponse = true;
                setUser({
                    stepToTake: "parentsInfo",
                    isLoading: true,
                    error: false
                });
            } else {
                setUser({
                    stepToTake: "childsInfo",
                    isLoading: false,
                    error: true
                });
            }
        }).catch(() => {
            setUser({
                stepToTake: "childsInfo",
                isLoading: false,
                error: true
            });
        });

        return insertResponse;
    }

    const getParentsInfo = async () => {

        let fullChildsData = {};

        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'getParentsInfo',
            user: globalVals.user
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.getStatus === 'SUCCESS') {
                setUser({
                    ...user,
                    isLoading: false,
                    error: false
                });

                fullChildsData = postResult.parentsInfo;
            } else {
                setUser({
                    ...user,
                    isLoading: false,
                    error: true
                });
            }
        }).catch(() => {
            setUser({
                ...user,
                isLoading: false,
                error: true
            });
        });

        return fullChildsData;
    }

    const setParentsData = async (formData) => {
        let insertResponse = false;

        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'setParentsData',
            user: globalVals.user,
            credentials: { formData }
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.updateStatus === 'SUCCESS') {
                globalVals.currentStep = "otherInfo";
                insertResponse = true;
                setUser({
                    stepToTake: "otherInfo",
                    isLoading: true,
                    error: false
                });
            } else {
                setUser({
                    stepToTake: "parentsInfo",
                    isLoading: false,
                    error: true
                });
            }
        }).catch(() => {
            setUser({
                stepToTake: "parentsInfo",
                isLoading: false,
                error: true
            });
        });

        return insertResponse;
    }

    const getPersonsInfo = async () => {

        let fullPersonssData = {};
        let fullUnitsData = {};

        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'getOtherPersonsInfo',
            user: globalVals.user
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.getStatus === 'SUCCESS') {
                setUser({
                    ...user,
                    isLoading: false,
                    error: false
                });

                fullPersonssData = postResult.personsInfo;
                fullUnitsData = postResult.unitsInfo;
            } else {
                setUser({
                    ...user,
                    isLoading: false,
                    error: true
                });
            }
        }).catch(() => {
            setUser({
                ...user,
                isLoading: false,
                error: true
            });
        });

        return { personsInfo: fullPersonssData, unitsInfo: fullUnitsData };
    }

    const setPersonsData = async (formData) => {
        let insertResponse = false;

        setUser({
            ...user,
            isLoading: true,
            error: null,
        });

        let postData = encryptData({
            data_type: 'setOtherPersonsData',
            user: globalVals.user,
            credentials: { formData }
        });

        await axios.post(
            baseAPI,
            postData,
            { headers: { 'Content-Type': 'application/json' } }
        ).then((postResult) => {
            postResult = JSON.parse(decryptData(postResult.data));

            if (postResult.updateStatus === 'SUCCESS') {
                globalVals.currentStep = "welcomeBack";
                insertResponse = true;
                setUser({
                    stepToTake: "welcomeBack",
                    isLoading: true,
                    error: false
                });
            } else {
                setUser({
                    stepToTake: "otherInfo",
                    isLoading: false,
                    error: true
                });
            }
        }).catch(() => {
            setUser({
                stepToTake: "otherInfo",
                isLoading: false,
                error: true
            });
        });

        return insertResponse;
    }

    const logout = () => {
        localStorage.removeItem('loginStatus')

        window.location.href = '/'
    }

    return (
        <customerContext.Provider
            value={{
                setUser,
                user,
                sendContactMail,
                getOTP,
                getPersonalInfo,
                setPersonalInfo,
                getEagerData,
                setEagerData,
                getSpuseInfo,
                setSpuseInfo,
                getChildsInfo,
                setChildsData,
                getParentsInfo,
                setParentsData,
                getPersonsInfo,
                setPersonsData,
                logout
            }}
        >
            {children}
        </customerContext.Provider>
    )
}

export { customerContext, CustomerProvider }
