import React, { useEffect, useState, useContext } from 'react';
import { Bars } from 'react-loader-spinner';
import { customerContext } from '../../http/axios';
import { encryptData, decryptData } from '../../http/encrypt';
import Multiselect from 'multiselect-react-dropdown';
import globalVals from '../../globalVals';

const CommunicationInfo = () => {

  const FavSportOptions = globalVals.FavSportOptions;

  const InterestHobbiesOptions = globalVals.InterestHobbiesOptions;

  const MemberShipOptions = globalVals.MemberShipOptions;

  const { user, getEagerData, setEagerData } = useContext(customerContext);
  const [showError, setShowError] = useState(false);
  let stepToGo = "stepTwoForm";

  const { error, isLoading, stepToTake } = user;

  if (stepToTake !== undefined) {
    stepToGo = stepToTake;
  }

  const [customerRowID, setCustomerRowID] = useState("");
  const [PrefLang, setPrefLang] = useState("");
  const [CommEvent, setCommEvent] = useState("");
  const [PrefComm, setPrefComm] = useState("");
  const [MartStat, setMartStat] = useState("");
  const [HaveKids, setHaveKids] = useState("");
  const [FamilyCount, setFamilyCount] = useState("");
  const [CarCount, setCarCount] = useState("");
  const [CarsData, setCarsData] = useState({});
  const [HavePets, setHavePets] = useState("N");
  const [PetCount, setPetCount] = useState("");
  const [sports, setSports] = useState([]);
  const [Hobbies, setHobbies] = useState([]);
  const [MemberShip, setMemberShip] = useState([]);

  const [carInput, setCarInput] = useState([]);
  const [MariedStatus, SetMariedStatus] = useState(false);
  const [PetsView, SetPetsView] = useState(false);

  const [carTypes, setCarTypes] = useState([]);
  const [carModels, setCarModels] = useState([]);

  useEffect(() => {
    updateEagerData();

    let tempCarTypes = [];
    globalVals.carTypesOptions.map((i, k) => {
      tempCarTypes.push(<option key={k} value={i.type}>{i.type}</option>);
    });
    setCarTypes(tempCarTypes);

    let tempCarModels = [];
    globalVals.carModelsOptions.map((i, k) => {
      tempCarModels.push(<option key={k} value={i.model}>{i.model}</option>);
    });
    setCarModels(tempCarModels);
  }, []);

  const updateEagerData = () => getEagerData().then((responseData) => {

    setCustomerRowID(responseData.customerRowID);
    setPrefLang(responseData.PrefLang);
    setCommEvent(responseData.CommEvent);
    setPrefComm(responseData.PrefComm);
    setMartStat(responseData.MartStat);
    setHaveKids(responseData.HaveKids);
    setFamilyCount(responseData.FamilyCount);
    setCarCount(responseData.CarCount);
    setCarsData(responseData.CarsData);
    setHavePets(responseData.HavePets);
    setPetCount(responseData.PetCount);
    setSports(responseData.sports);
    setHobbies(responseData.Hobbies);
    setMemberShip(responseData.MemberShip);

    if (responseData.MartStat === "S") {
      SetMariedStatus(false);
      setFamilyCount("1");
    } else {
      SetMariedStatus(true);
    }

    if (responseData.HavePets === "Y") {
      SetPetsView(true);
    } else {
      SetPetsView(false);
      setPetCount("");
    }
  });

  const preventEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let tempFormData = {
      customerRowID: customerRowID,
      clientPrefLang: PrefLang,
      clientCommEvent: CommEvent,
      clientPrefComm: PrefComm,
      clientMartStat: MartStat,
      clientHaveKids: HaveKids,
      clientFamilyCount: FamilyCount,
      clientCarCount: CarCount,
      clientCarsData: CarsData,
      clientHavePets: HavePets,
      clientPetCount: PetCount,
      clientsports: sports,
      clientHobbies: Hobbies,
      clientMemberShip: MemberShip,
    };

    if (stepToGo === "stepTwoForm") {
      setEagerData(JSON.stringify(tempFormData)).then((respsonse) => {
        if ((globalVals.currentStep === "stepThreeForm" || globalVals.currentStep === "childsInfo" || globalVals.currentStep === "parentsInfo" || globalVals.currentStep === "otherInfo") && respsonse) {

          let stepNum = 3;
          switch (globalVals.currentStep) {
            case "childsInfo":
              stepNum = 4;
              break;
            case "parentsInfo":
              stepNum = 5;
              break;
            case "otherInfo":
              stepNum = 6;
              break;
            default:
              stepNum = 3;
              break;
          }

          const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

          const newSession = {
            ...session,
            currentStep: globalVals.currentStep,
            preStep: globalVals.preStep,
            webStepNum: stepNum
          };
          sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));

          return window.location.href = "/";
        } else {
          setShowError(true);
          setTimeout(() => setShowError(false), 8000);
        }
      });
    }
    if (error) {
      setShowError(true);
      setTimeout(() => setShowError(false), 8000);
    }
  }

  useEffect(() => {
    // Initialize an empty object to hold car data
    let updatedCarsData = {};
    let tempCarInputs = [];

    if (CarCount > 0) {
      for (let ic = 0; ic < CarCount; ic++) {
        let carIden = `car_${ic}`;
        let carData = CarsData[carIden] || { type: "", model: "", plate: "" };

        tempCarInputs.push(
          <div className="row w-100 mt-2" key={ic}>
            <div className="col-md-4">
              <label htmlFor={`car_type_${ic}`}>Car Type</label>
              <select
                className="w-100 px-2"
                id={`car_type_${ic}`}
                name="car_type[]"
                value={carData.type}
                onChange={(e) => {
                  const newCarData = { ...carData, type: e.target.value };
                  updatedCarsData = { ...updatedCarsData, [carIden]: newCarData };
                  setCarsData({ ...CarsData, ...updatedCarsData });
                }}
              >
                <option value="">Select Car Type</option>
                {carTypes}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor={`car_model_${ic}`}>Car Model</label>
              <select
                className="w-100 px-2"
                id={`car_model_${ic}`}
                name="car_model[]"
                value={carData.model}
                onChange={(e) => {
                  const newCarData = { ...carData, model: e.target.value };
                  updatedCarsData = { ...updatedCarsData, [carIden]: newCarData };
                  setCarsData({ ...CarsData, ...updatedCarsData });
                }}
              >
                <option value="">Select Car Model</option>
                {carModels}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor={`plate_num_${ic}`}>Plate Number *</label>
              <input
                type="text"
                className="form-control"
                id={`plate_num_${ic}`}
                name="plate_num[]"
                value={carData.plate}
                required={true}
                onChange={(e) => {
                  const newCarData = { ...carData, plate: e.target.value };
                  updatedCarsData = { ...updatedCarsData, [carIden]: newCarData };
                  setCarsData({ ...CarsData, ...updatedCarsData });
                }}
              />
            </div>
          </div>
        );
      }
    }

    setCarInput(tempCarInputs);
  }, [CarCount, CarsData]);



  return (
    <div className="container p-3 p-md-5 position-relative">

      {
        isLoading && (
          <Bars color="#003da7" ariaLabel="bars-loading" wrapperClass="loadingBars" visible={true} />
        )
      }

      <div className="row mb-3 mb-md-5">
        <div className="col-12">
          <h4 className='stepHeader'>Step 2/3</h4>
          <h2 className="heading text-capitalize text-start mb-1"><strong>Eager to Know You Better</strong></h2>
          <p className="fw-light h5 text-start"> Not all communication is constructed equally; and we're keen to customize all of our communication to suit your preferences. </p>
        </div>
      </div>
      <form method='post' className='mt-5 d-flex flex-column' onSubmit={handleSubmit} onKeyDown={preventEnter}>
        <div className="row mb-3 mb-md-5">
          <div className="container mb-3 mb-md-5">
            <div className="row mb-2">
              <h5><b>Communication</b></h5>
            </div>
            <div className="row g-3 g-md-5">
              <div className="col-md-6">
                <p>Preferred Language of Communication *</p>
                <div className="d-flex w-100 gap-5">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="arabic" value="AR" name="langPref" required={true} onChange={(e) => setPrefLang(e.target.value)} checked={(PrefLang === "ARABIC" || PrefLang === "AR") ? true : false} />
                    <label className="form-check-label" htmlFor="arabic"> Arabic </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="english" value="US" name='langPref' required={true} onChange={(e) => setPrefLang(e.target.value)} checked={(PrefLang === "AMERICAN" || PrefLang === "US") ? true : false} />
                    <label className="form-check-label" htmlFor="english"> English </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <p>Would you like to be notified of community events? *</p>
                <div className="d-flex w-100 gap-5">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="event_yes" value="Y" name="event_req" required={true} onChange={(e) => setCommEvent(e.target.value)} checked={(CommEvent === "Y") ? true : false} />
                    <label className="form-check-label" htmlFor="event_yes"> Yes </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="event_no" value="N" name="event_req" required={true} onChange={(e) => setCommEvent(e.target.value)} checked={(CommEvent === "N") ? true : false} />
                    <label className="form-check-label" htmlFor="event_no"> No </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <p>Preferred Channel of Communication *</p>
                <div className="d-flex w-100 gap-5">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="sms" value="SMS" name='prefComm' required={true} onChange={(e) => setPrefComm(e.target.value)} checked={(PrefComm === "SMS") ? true : false} />
                    <label className="form-check-label" htmlFor="sms"> SMS </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="whatsapp" value="WhatsApp" name='prefComm' required={true} onChange={(e) => setPrefComm(e.target.value)} checked={(PrefComm === "WhatsApp") ? true : false} />
                    <label className="form-check-label" htmlFor="whatsapp"> WhatsApp </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="cemail" value="EMAIL" name='prefComm' required={true} onChange={(e) => setPrefComm(e.target.value)} checked={(PrefComm === "EMAIL") ? true : false} />
                    <label className="form-check-label" htmlFor="cemail"> Email </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-md-5">
          <div className="container  mb-3 mb-md-5">
            <div className="row mb-2">
              <h5><b>Community</b></h5>
            </div>
            <div className="row g-3 g-md-5">
              <div className="col-md-6">
                <label htmlFor="marital" className="mb-2"> Marital Status * </label>
                <div className="d-flex">
                  <select className="w-100 px-2" id="marital" name='marital_status' required={true} onChange={(e) => {
                    if (e.target.value === "S") {
                      SetMariedStatus(false);
                      setFamilyCount("1");
                    } else {
                      SetMariedStatus(true);
                    }
                    setMartStat(e.target.value)
                  }} value={MartStat} >
                    <option value="S">Single</option>
                    <option value="M">Married</option>
                    <option value="D">Divorced</option>
                    <option value="W">Widowed</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                {MariedStatus &&
                  <>
                    <p>Do you have kids? *</p>
                    <div className="d-flex w-100 gap-5">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" id="kids_yes" value="Y" name="haveKids" required={true} onChange={(e) => setHaveKids(e.target.value)} checked={(HaveKids === "Y") ? true : false} />
                        <label className="form-check-label" htmlFor="kids_yes"> Yes </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" id="kids_no" value="N" name="haveKids" required={true} onChange={(e) => setHaveKids(e.target.value)} checked={(HaveKids === "N") ? true : false} />
                        <label className="form-check-label" htmlFor="kids_no"> No </label>
                      </div>
                    </div>
                  </>
                }
              </div>

              <div className="col-md-6">
                <label htmlFor="familyMembers" className="mb-2"> Number of Family Members (future home residents) * </label>
                <div className="d-flex">
                  <select className="w-100 px-2" id="familyMembers" required={true} readOnly={MariedStatus ? false : true} onChange={(e) => setFamilyCount(e.target.value)} value={FamilyCount} >
                    {
                      MariedStatus ?
                        <>
                          <option>Select</option>
                        </>
                        :
                        null
                    }
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="carsCount" className="mb-2"> Cars Count * </label>
                <div className="d-flex w-100">
                  <select className="w-100 px-2" id="carsCount" required={true} onChange={(e) => setCarCount(e.target.value)} value={CarCount}>
                    <option value="0">No Car/s</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </div>
              {carInput.length > 0 &&
                <div className="col-md-12">
                  <legend >Cars Informations *</legend>
                  <div id='car_inputs' className='w-100'>
                    {carInput}
                  </div>
                </div>
              }
              <div className="col-md-6">
                <p>Do you have any pets? *</p>
                <div className="d-flex w-100 gap-5">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="pets_yes" name='have_pets' value="Y" required={true} onChange={(e) => { SetPetsView(true); setHavePets(e.target.value); }} checked={(HavePets === "Y") ? true : false} />
                    <label className="form-check-label" htmlFor="pets_yes"> Yes </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="pets_no" name='have_pets' value="N" required={true} onChange={(e) => { setPetCount(""); SetPetsView(false); setHavePets(e.target.value); }} checked={(HavePets === "N") ? true : false} />
                    <label className="form-check-label" htmlFor="pets_no"> No </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {
                  PetsView &&
                  <>
                    <label htmlFor="pets_count" className="mb-2"> Please specify how many *</label>
                    <div className="">
                      <select className="w-100 px-2" id="pets_count" name='pets_count' required={true} value={PetCount} onChange={(e) => setPetCount(e.target.value)} >
                        <option>Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </>
                }
              </div>
              <div className="col-md-6">
                <label className="mb-2">Favorite Sports (Select one or more) *</label>
                <div className="dropdown w-100">

                  <Multiselect
                    id="sports"
                    options={FavSportOptions}
                    required={true}
                    selectedValues={sports}
                    onSelect={(values) => setSports(values)}
                    onRemove={(values) => setSports(values)}
                    displayValue="sport"
                  />

                </div>
              </div>

              <div className="col-md-6">
                <label className="mb-2">Interests and Hobbies *</label>
                <div className="dropdown w-100">


                  <Multiselect
                    id="cHobbies"
                    options={InterestHobbiesOptions}
                    selectedValues={Hobbies}
                    required={true}
                    onSelect={(values) => setHobbies(values)}
                    onRemove={(values) => setHobbies(values)}
                    displayValue="intHob"
                  />

                </div>
              </div>

              <div className="col-md-6">
                <label className="mb-2">Club Membership *</label>
                <div className="dropdown w-100">

                  <Multiselect
                    id='cClubMembership'
                    options={MemberShipOptions}
                    selectedValues={MemberShip}
                    required={true}
                    onSelect={(values) => setMemberShip(values)}
                    onRemove={(values) => setMemberShip(values)}
                    displayValue="clubsMemberShip"
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="submit d-flex justify-content-end mb-1 mb-md-5">
            <button type='submit' className="rounded-pill border-0 main-color px-3 px-md-5 text-white fw-bold py-2">Submit & Continue <i className="bi bi-arrow-right"></i></button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CommunicationInfo;
