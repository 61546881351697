import { useEffect, useState } from "react";
import globalVals from "../../../globalVals";

export default function NanniesForm(props) {
  const formNum = parseInt(props.formNumber);
  const formIden = "person_" + formNum;
  const [unitsData, setunitsData] = useState(props.unitsData);

  const [personFinal, setPersonFinal] = useState(props.personsAllData[formIden]);

  const [specialAssComm, setSpecialAssComm] = useState(false);
  const [showFunction, setShowFunction] = useState(false);
  const [years, setYears] = useState([]);
  const [functionService, setFunctionService] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [allNationalities, setallNationalities] = useState([]);
  const [allCountryCodes, setallCountryCodes] = useState([]);
  const [sassspecifyOptions, setSassspecifyOptions] = useState([]);
  const [egypLength, setegypLength] = useState(false);

  useEffect(() => props.personsSetData({ ...props.personsAllData, [formIden]: personFinal }), [personFinal]);

  useEffect(() => {

    if (personFinal.psassintance_ == "Y") {
      setSpecialAssComm(true);
    } else {
      setSpecialAssComm(false);
    }

    if (personFinal.pMVDeligated == "Y") {
      setShowFunction(true);
    } else {
      setShowFunction(false);
    }

    let tempYears = [];
    let cYear = new Date().getFullYear();
    for (let y = 1900; y <= cYear; y++) {
      tempYears.push(<option key={y} value={y}>{y}</option>);
    }

    let tempNati = [];
    let tempCodes = [];
    globalVals.countries.map((i, k) => {
      tempNati.push(<option key={k} value={i.isocode}>{i.nationality}</option>);
      tempCodes.push(<option key={k} value={i.code}>{i.name} (+{i.code})</option>);
    });
    setallNationalities(tempNati);
    setallCountryCodes(tempCodes);
    setYears(tempYears);

    let tempsassspecifyOptions = [];
    globalVals.disabilitiesOptions.map((i, k) => {
      tempsassspecifyOptions.push(<option key={k} value={i.disability}>{i.disability}</option>);
    });
    setSassspecifyOptions(tempsassspecifyOptions);

    let tempServiceOptions = [];
    globalVals.servicesOptions.map((i, k) => {
      tempServiceOptions.push(<option key={k} value={i.service}>{i.service}</option>);
    });
    setServiceOptions(tempServiceOptions);

    let tempUnitsInfo = [];
    unitsData.map((i, k) => {
      tempUnitsInfo.push(<option key={k} value={i.unitID}>{i.unitProject + ' [' + i.unitCode + ']'}</option>);
    });
    setunitsData(tempUnitsInfo);
  }, []);

  return (
    <>
      <div className="container mb-5">
        <div className="row mb-2">
          <a href="" className="mb-2 fw-bold mainColor text-decoration-none"> Person #{formNum} </a>
          <h5 className=""><b>Personal Data</b></h5>
        </div>
        <div className="row g-3 g-md-5 mb-3 mb-md-5">
          <div className="col-md-6">
            <label htmlFor={"fullNameID_" + formNum} className="form-label"> Full Name * </label>
            <input type="text" className="form-control" id={"fullNameID_" + formNum} value={personFinal.pname_} onChange={(e) => setPersonFinal({ ...personFinal, pname_: e.target.value })} />
          </div>
          <div className="col-md-6">
            <p>Gender *</p>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" required id={"male_" + formNum} value="MALE" onChange={(e) => setPersonFinal({ ...personFinal, pgender_: e.target.value })} checked={((personFinal.pgender_ === "MALE") ? true : false)} />
              <label className="form-check-label" htmlFor={"male_" + formNum}> Male </label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" required id={"female_" + formNum} value="FEMALE" onChange={(e) => setPersonFinal({ ...personFinal, pgender_: e.target.value })} checked={((personFinal.pgender_ === "FEMALE") ? true : false)} />
              <label className="form-check-label" htmlFor={"female_" + formNum}> Female </label>
            </div>
          </div>
          <div className="col-md-6">
            <p> Choose one from the following and fill it in the next box * </p>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" id={"idnum_" + formNum} value="ID_NUMBER" required onChange={(e) => setPersonFinal({ ...personFinal, pidType_: e.target.value })} checked={((personFinal.pidType_ === "ID_NUMBER") ? true : false)} />
              <label className="form-check-label" htmlFor={"idnum_" + formNum}> ID </label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" id={"passport_" + formNum} value="PASSPORT" required onChange={(e) => setPersonFinal({ ...personFinal, pidType_: e.target.value })} checked={((personFinal.pidType_ === "PASSPORT") ? true : false)} />
              <label className="form-check-label" htmlFor={"passport_" + formNum}> Passport </label>
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor={"id_num_" + formNum} className="form-label"> ID / Passport * </label>
            <input type="text" className="form-control" id={"id_num_" + formNum} value={personFinal.pidNumber_} onChange={(e) => setPersonFinal({ ...personFinal, pidNumber_: e.target.value })} />
          </div>
          <div className="col-md-6">
            <label htmlFor={"nationality_" + formNum} className="form-label"> Nationality * </label>
            <select className="w-100 px-2" id={"nationality_" + formNum} value={personFinal.pnationality_} onChange={(e) => setPersonFinal({ ...personFinal, pnationality_: e.target.value })} >
              <option value>Choose</option>
              {allNationalities}
            </select>
          </div>
          <div className="col-md-6">
            <label className="mb-2" id={"dob_day_" + formNum}>Date of birth*</label>
            <div className="d-flex gap-2">
              <select className="w-auto px-2" id={"dob_day_" + formNum} name='dob_day' required value={personFinal.pdobDay_ || ""} onChange={(e) => setPersonFinal({ ...personFinal, pdobDay_: e.target.value })} >
                <option key={1} value>Day</option>
                <option key={2} value="01">1</option>
                <option key={3} value="02">2</option>
                <option key={4} value="03">3</option>
                <option key={5} value="04">4</option>
                <option key={6} value="05">5</option>
                <option key={7} value="06">6</option>
                <option key={8} value="07">7</option>
                <option key={9} value="08">8</option>
                <option key={10} value="09">9</option>
                <option key={11} value="10">10</option>
                <option key={12} value="11">11</option>
                <option key={13} value="12">12</option>
                <option key={14} value="13">13</option>
                <option key={15} value="14">14</option>
                <option key={16} value="15">15</option>
                <option key={17} value="16">16</option>
                <option key={18} value="17">17</option>
                <option key={19} value="18">18</option>
                <option key={20} value="19">19</option>
                <option key={21} value="20">20</option>
                <option key={22} value="21">21</option>
                <option key={23} value="22">22</option>
                <option key={24} value="23">23</option>
                <option key={25} value="24">24</option>
                <option key={26} value="25">25</option>
                <option key={27} value="26">26</option>
                <option key={28} value="27">27</option>
                <option key={29} value="28">28</option>
                <option key={30} value="29">29</option>
                <option key={31} value="30">30</option>
                <option key={32} value="31">31</option>
              </select>
              <select className="w-auto px-2" id={"dob_month_" + formNum} name='dob_montg' required value={personFinal.pdobMonth_} onChange={(e) => setPersonFinal({ ...personFinal, pdobMonth_: e.target.value })} >
                <option key={1} value>Month</option>
                <option key={2} value="01">Jan.</option>
                <option key={3} value="02">Feb.</option>
                <option key={4} value="03">Mar.</option>
                <option key={5} value="04">Apr.</option>
                <option key={6} value="05">May.</option>
                <option key={7} value="06">Jun.</option>
                <option key={8} value="07">Jul.</option>
                <option key={9} value="08">Aug.</option>
                <option key={10} value="09">Sept.</option>
                <option key={11} value="10">Oct.</option>
                <option key={12} value="11">Nov.</option>
                <option key={13} value="12">Dec.</option>
              </select>
              <select className="w-auto px-2" id={"dob_year_" + formNum} name='dob_year' required value={personFinal.pdobYear_} onChange={(e) => setPersonFinal({ ...personFinal, pdobYear_: e.target.value })} >
                <option key={1} value>Year</option>
                {years}
              </select>
            </div>
          </div>
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <h5 className=""><b>Communication</b></h5>
        </div>
        <div className="row g-3 g-md-5 mb-3 mb-md-5">
          <div className="col-md-6">
            <label htmlFor="phoneNumber" className="form-label"> Contact Number * </label>
            <div className="d-flex gap-2">
              <select className="w-25 px-2" id={"pmobileCountry_" + formNum} required value={personFinal.pmobileCountry_} onChange={(e) => {
                if (e.target.value == "20") {
                  setegypLength(true);
                } else {
                  setegypLength(false);
                }

                setPersonFinal({ ...personFinal, pmobileCountry_: e.target.value });
              }
              } >
                <option key={1} value>Select Country Code</option>
                {allCountryCodes}
              </select>
              {
                egypLength ?
                  <input type="text" className="form-control" id={"pphoneNumber_" + formNum} value={personFinal.pphoneNumber_} onChange={(e) => setPersonFinal({ ...personFinal, pphoneNumber_: e.target.value })} minLength={10} maxLength={10} />
                  :
                  <input type="text" className="form-control" id={"pphoneNumber_" + formNum} value={personFinal.pphoneNumber_} onChange={(e) => setPersonFinal({ ...personFinal, pphoneNumber_: e.target.value })} />
              }
            </div>
          </div>
          <div className="col-md-6">
            <label className="mb-2" id={"pRole_" + formNum}>Person Role *</label>
            <div className="d-flex gap-2">
              <select className="w-100 px-2" id={"pRole_" + formNum} name='dob_montg' required value={personFinal.pServiceType} onChange={(e) => setPersonFinal({ ...personFinal, pServiceType: e.target.value })} >
                <option key={1} value>Roles</option>
                <option key={2} value="Housekeeper">Housekeeper</option>
                <option key={3} value="Nanny">Nanny</option>
                <option key={4} value="Driver">Driver</option>
                <option key={5} value="Gardener">Gardener</option>
                <option key={6} value="Security">Security</option>
                <option key={7} value="Gate keeper">Gate keeper</option>
                <option key={8} value="Cook">Cook</option>
                <option key={9} value="Butler">Butler</option>
                <option key={10} value="Handyman">Handyman</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <h5 className=""><b>Function</b></h5>
        </div>
        <div className="row g-3 g-md-5 mb-3 mb-md-5">
          <div className="col-md-6">
            <div>
              <p className="mb-0"> Is it possible to deal with MV teams on ground? </p>
              <small> confirm to delegate their input to take decision on your behalf regarding the compound services </small>
            </div>
            <div className="mt-2 form-check form-check-inline">
              <input className="form-check-input" type="radio" value="Y" id={"deliyes_" + formNum} required onChange={(e) => { setShowFunction(true); setPersonFinal({ ...personFinal, pMVDeligated: e.target.value }); }} checked={((personFinal.pMVDeligated === "Y") ? true : false)} />
              <label className="form-check-label" htmlFor={"deliyes_" + formNum}> Yes </label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" value="N" id={"delino_" + formNum} required onChange={(e) => { setShowFunction(false); setPersonFinal({ ...personFinal, pMVDeligated: e.target.value }); }} checked={((personFinal.pMVDeligated === "N") ? true : false)} />
              <label className="form-check-label" htmlFor={"delino_" + formNum} > No </label>
            </div>
          </div>
          {showFunction &&
            <>
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <label className="mb-2" id={"reqServ_" + formNum}>Required Service *</label>
                <div className="dropdown w-100" >
                  <select className="w-100 px-2" id={"reqServ_" + formNum} name={"reqServ_" + formNum} required value={personFinal.pServiceDeligated} onChange={(e) => setPersonFinal({ ...personFinal, pServiceDeligated: e.target.value })} >
                    <option key={1} value>Roles</option>
                    {serviceOptions}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <label className="mb-2" id={"unit_" + formNum}>Unit *</label>
                <div className="dropdown w-100" >
                  <select className="w-100 px-2" id={"unit_" + formNum} name={"unit_" + formNum} required value={personFinal.pServiceUnit} onChange={(e) => setPersonFinal({ ...personFinal, pServiceUnit: e.target.value })} >
                    <option value>Select Unit</option>
                    {unitsData}
                  </select>
                </div>
              </div>
            </>
          }
        </div>
        <div className="row">
          <h5 className=""><b>Community</b></h5>
        </div>
        <div className="row g-3 g-md-5 mb-3 mb-md-5">
          <div className="col-md-6">
            <p>Any special impairment that needs special assistance *</p>
            <div className="form-check form-check-inline">
              <input className="form-check-input" name={"syes_" + formNum} type="radio" id={"syes_" + formNum} value="Y" onChange={(e) => { setSpecialAssComm(true); setPersonFinal({ ...personFinal, psassintance_: e.target.value }) }} checked={((personFinal.psassintance_ === "Y") ? true : false)} />
              <label className="form-check-label" htmlFor={"syes_" + formNum}> Yes </label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name={"sno_" + formNum} id={"sno_" + formNum} value="N" onChange={(e) => { setPersonFinal({ ...personFinal, psassintanceComm_: "" }); setSpecialAssComm(false); setPersonFinal({ ...personFinal, psassintance_: e.target.value }) }} checked={((personFinal.psassintance_ === "N") ? true : false)} />
              <label className="form-check-label" htmlFor={"sno_" + formNum}> No </label>
            </div>
          </div>
          {specialAssComm &&
            <div className="col-md-6">
              <label htmlFor={"sspecify_" + formNum} className="form-label"> If yes please specify * </label>
              <select className="w-100 px-2" name={"sspecify_" + formNum} id={"sspecify_" + formNum} value={personFinal.psassintanceComm_} onChange={(e) => setPersonFinal({ ...personFinal, psassintanceComm_: e.target.value })} required={true}>
                <option key={1} value>Select Disability</option>
                {sassspecifyOptions}
              </select>
            </div>
          }
        </div>
      </div>
    </>
  );
}
