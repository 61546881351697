import React, { useContext, useEffect, useState } from 'react'
import { customerContext } from '../../../http/axios';
import { encryptData, decryptData } from '../../../http/encrypt';
import globalVals from '../../../globalVals';
import { Bars } from 'react-loader-spinner';

import * as Icon from 'react-bootstrap-icons';
import { Button } from "react-bootstrap";
import ParentsForm from "./ParentsForm";

const Parents = () => {

  const { user, getParentsInfo, setParentsData } = useContext(customerContext);
  const [showError, setShowError] = useState(false);
  let stepToGo = "parentsInfo";

  const { error, stepToTake } = user;

  if (stepToTake !== undefined) {
    stepToGo = stepToTake;
  }

  const [ParentssData, setParentssData] = useState({});

  const [ParentsDataSet, setParentsDataSet] = useState(true);
  const [formIds, setFormIDs] = useState([]);
  let formKey = 0;

  useEffect(() => {
    updateParentsInfo();
  }, []);

  const updateParentsInfo = () => {
    if (ParentsDataSet) {
      getParentsInfo().then(responseData => {
        const ObjKeys = Object.keys(responseData);
        if (ObjKeys.length > 0) {
          setParentssData(responseData);
        } else {
          handleAddForm();
        }
      });
      setParentsDataSet(false);
    }
  };

  useEffect(() => {
    let FormIDss = [];
    Object.keys(ParentssData).map((k) => FormIDss.push(parseInt(k.replace("parent_", ""))));
    if (FormIDss.length == 0) {
      FormIDss.push(1);
    }
    setFormIDs(FormIDss);
  }, [ParentssData]);

  const handleAddForm = () => {
    let newFormID = (formIds.length + 1);
    setParentssData({
      ...ParentssData, ["parent_" + newFormID]: {
        parentRowID: "",
        parentID: "",
        cname_: "",
        cidType_: "",
        cid_: "",
        cdobDay_: "",
        cdobMonth_: "",
        cdobYear_: "",
        cnationality_: "",
        csports_: [],
        chobbies_: [],
        csassintance_: "N",
        csassintanceComm_: ""
      }
    });
  };

  const handleDeleteForm = (formId) => {
    if (formIds.length === 1 || formId === 0) {
      return;
    }
    setFormIDs(formIds.filter((id) => id !== formId));
  };

  const preventEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (stepToGo === "parentsInfo") {
      setParentsData(JSON.stringify(ParentssData)).then((respsonse) => {
        if (globalVals.currentStep === "otherInfo" && respsonse) {
          const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

          const newSession = {
            ...session,
            currentStep: "otherInfo",
          }
          globalVals.currentStep = "otherInfo";
          sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));
          return;
        } else {
          setShowError(true);
          setTimeout(() => setShowError(false), 8000);
        }
      });
    }
    if (error) {
      setShowError(true);
      setTimeout(() => setShowError(false), 8000);
    }
  }

  const handleSkip = (e) => {
    e.preventDefault();
    const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

    const newSession = {
      ...session,
      currentStep: "otherInfo",
      webStepNum: 6
    }
    globalVals.currentStep = "otherInfo";
    sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));
    return window.location.reload();
  }

  return (
    <>
      <label className="btn-outline mb-3">Other family members</label>
      <br />
      <p className='infoText'>* Please fill in if any other family members are living in the same home.</p>

      <form className="p-0" method='post' onSubmit={handleSubmit} onKeyDown={preventEnter}>
        <div className="row my-5 position-relative">

          {formIds.map((formId) => {
            formKey++;
            if (Object.keys(ParentssData).length > 0) {
              return (
                <div key={formKey}>
                  {formIds.length > 1 && formId > 1 && (
                    <div className="text-end">
                      <a className="btn btn-primary" onClick={() => handleDeleteForm(formId)}><Icon.Trash3Fill /></a>
                    </div>
                  )}
                  <ParentsForm
                    formNumber={formId}
                    showDelete={formIds.length > 1}
                    parentsAllData={ParentssData}
                    parentsSetData={setParentssData}
                  />
                </div>
              )
            } else {
              return <Bars color="#003da7" ariaLabel="bars-loading" wrapperClass="loadingBars h-100 w-100" visible={true} />
            }
          }
          )}

          {formIds.length > 0 && formIds.length < 10 && (
            <div className="row mb-3 mb-md-5">
              <a onClick={handleAddForm} className="fw-bold text-decoration-none mainColor mx-3 addBtn" role="button">Add Family Member +</a>
            </div>
          )}

          <div className="row">
            <div className="submit d-flex justify-content-end mb-1 mb-md-5">
              <Button type='button' variant="white" className="rounded-pill px-3 px-md-5 fw-bold mx-3 btnSkip" onClick={handleSkip}>Skip</Button>
              <Button type='submit' className="rounded-pill main-color px-3 px-md-5 text-white fw-bold"> Submit & Continue <i className="bi bi-arrow-right"></i></Button>
            </div>
          </div>

        </div >
      </form>
    </>
  );
};

export default Parents;