import React from 'react';
import globalVals from '../globalVals';
import { encryptData, decryptData } from '../http/encrypt';

const pleasureToMeetYou = () => {
  globalVals.currentStep = "stepOneForm";
  const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

  const newSession = {
    ...session,
    currentStep: "stepOneForm",
  };
  sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));

  return window.location.href = "/pleasure-to-meet-you";
}

const eagerToKnowYou = () => {
  globalVals.currentStep = "stepTwoForm";
  const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

  const newSession = {
    ...session,
    currentStep: "stepTwoForm",
  };
  sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));

  return window.location.href = "/eager-to-know-better";
}

const pleasureToMeetFamily = () => {
  globalVals.currentStep = "stepThreeForm";
  const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

  const newSession = {
    ...session,
    currentStep: "stepThreeForm",
  };
  sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));

  return window.location.href = "/family-info";
}

const WelcomeHome = () => {
  return (
    <div className="container p-0 p-md-5 mh-fh">
      <div className="row">
        <h2 className="heading text-capitalize mb-0 text-start">
          welcome home
        </h2>
        <h4 className="text-secondary">
          Please make sure to keep your profile updated.
        </h4>
      </div>
      <div className="row">
        <div className="col-md-6 mt-5">
          <a href="javascript:;" onClick={pleasureToMeetYou} className="h4 d-block text-start mb-2 mb-md-5 text-decoration-none mainColor">
            <span className="colorGold">1-</span> Pleasure to Meet You{" "}
          </a>
          <a href="javascript:;" onClick={eagerToKnowYou} className="h4 d-block text-start mb-2 mb-md-5 text-decoration-none mainColor">
            <span className="colorGold">2-</span> Eager to Know You Better
          </a>
          <a href="javascript:;" onClick={pleasureToMeetFamily} className="h4 d-block text-start mb-2 mb-md-5 text-decoration-none mainColor">
            <span className="colorGold">3-</span> Pleasure to Meet the Family
          </a>
        </div>
      </div>
    </div>
  );
};

export default WelcomeHome;
