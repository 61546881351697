import React, { useContext, useEffect, useState } from 'react'
import { Bars } from 'react-loader-spinner';
import { customerContext } from '../../http/axios';
import { decryptData } from '../../http/encrypt';
import globalVals from '../../globalVals';

import Juniors from "./FamilyInfo/Juniors";
import NanniesDrivers from "./FamilyInfo/NanniesDrivers";
import Parents from "./FamilyInfo/Parents";
import SpouseInfo from "./FamilyInfo/SpouseInfo";


const FamilyInfo = () => {

  if (!(globalVals.loggedIn && globalVals.userName)) {
    window.location.href = "/";
  }

  const { user } = useContext(customerContext);
  const [stepToGo, setStepToGo] = useState("spouseInfo");
  const { error, isLoading, stepToTake } = user;
  const [getFromSession, setGetFromSession] = useState(true);
  const [isLoadingIN, setIsLoadingIN] = useState(isLoading);

  useEffect(() => {
    if (getFromSession) {
      setGetFromSession(false);
      let currentStepToGo = null;
      const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

      switch (parseInt(session.webStepNum)) {
        case 4:
          currentStepToGo = "childsInfo";
          break;
        case 5:
          currentStepToGo = "parentsInfo";
          break;
        case 6:
          currentStepToGo = "otherInfo";
          break;
        default:
          currentStepToGo = "spouseInfo";
          break;
      }
      setStepToGo(currentStepToGo);
    } else {
      setStepToGo(stepToTake);
    }
  }, [stepToTake]);

  if (stepToGo === undefined) {
    if (globalVals.currentStep !== "stepThreeForm") {
      setStepToGo(globalVals.currentStep);
      setIsLoadingIN(false)
    } else {
      setStepToGo("spouseInfo");
    }
  }

  return (
    <div className="container p-3 p-md-5 position-relative">
      {
        isLoadingIN && (
          <Bars color="#003da7" ariaLabel="bars-loading" wrapperClass="loadingBars" visible={true} />
        )
      }

      <div className="row mb-5">
        <div className="col-12">
          <h4 className='stepHeader'>Step 3/3</h4>
          <h2 className="heading text-capitalize text-start mb-1"><strong>Pleasure to meet the Family</strong></h2>
          <p className="fw-light fs-3 text-start">Nothing says "home" better than a personalized experience. We'd love to know more about your preferences and your family to make sure all of your needs are met and your aspirations are lived up to.</p>
        </div>
      </div>

      {
        (stepToGo == "spouseInfo") ?
          <SpouseInfo />
          :
          (stepToGo == "childsInfo") ?
            <Juniors />
            :
            (stepToGo == "parentsInfo") ?
              <Parents />
              :
              (stepToGo == "otherInfo") ?
                <NanniesDrivers />
                :
                null

      }

    </div>
  );
};

export default FamilyInfo;
