import React, { useContext, useEffect, useState } from 'react'
import { customerContext } from '../../../http/axios';
import { encryptData, decryptData } from '../../../http/encrypt';
import globalVals from '../../../globalVals';
import { Bars } from 'react-loader-spinner';

import * as Icon from 'react-bootstrap-icons';
import JuniorsForm from "./JuniorsForm";

const Juniors = () => {

  useEffect(() => {
    const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

    const newSession = {
      ...session,
      currentStep: "childsInfo",
    };
    sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));
  }, []);

  const { user, getChildsInfo, setChildsData } = useContext(customerContext);
  const [showError, setShowError] = useState(false);
  let stepToGo = "childsInfo";

  const { error, stepToTake } = user;

  if (stepToTake !== undefined) {
    stepToGo = stepToTake;
  }

  const [JuniorsData, setJuniorsData] = useState({});
  const [JuniorsDataSet, setJuniorsDataSet] = useState(true);

  const [formIds, setFormIDs] = useState([]);
  let formKey = 0;

  useEffect(() => {
    if (JuniorsDataSet) {
      getChildsInfo().then(responseData => {
        const ObjKeys = Object.keys(responseData);
        if (ObjKeys.length > 0) {
          setJuniorsData(responseData);
        } else {
          handleAddForm();
        }
      });
      setJuniorsDataSet(false);
    }
  }, []);

  useEffect(() => {
    let FormIDss = [];
    Object.keys(JuniorsData).map((k) => FormIDss.push(parseInt(k.replace("child_", ""))));
    if (FormIDss.length == 0) {
      FormIDss.push(1);
    }
    setFormIDs(FormIDss);
  }, [JuniorsData]);

  const handleAddForm = () => {
    let newFormID = (formIds.length + 1);
    setJuniorsData({
      ...JuniorsData, ["child_" + newFormID]: {
        childRowID: "",
        childID: "",
        cname_: "",
        cidType_: "",
        cid_: "",
        cgender_: "",
        cdobDay_: "",
        cdobMonth_: "",
        cdobYear_: "",
        cnationality_: "",
        cmobileCountry_: "",
        cphoneNumber_: "",
        ceducationStage_: "",
        cschoolName_: "",
        cschoolGrade_: "",
        cschoolBus_: "",
        csports_: [],
        chobbies_: [],
        csassintance_: "N",
        csassintanceComm_: ""
      }
    });
  };

  const preventEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  }

  const handleDeleteForm = (formId) => {
    if (formIds.length === 1 || formId === 0) {
      return;
    }
    setFormIDs(formIds.filter((id) => id !== formId));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (stepToGo === "childsInfo") {
      setChildsData(JSON.stringify(JuniorsData)).then((respsonse) => {
        if (globalVals.currentStep === "parentsInfo" && respsonse) {
          const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

          const newSession = {
            ...session,
            currentStep: "parentsInfo",
          }
          globalVals.currentStep = "parentsInfo";
          sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));
          return;
        } else {
          setShowError(true);
          setTimeout(() => setShowError(false), 8000);
        }
      });
    }
    if (error) {
      setShowError(true);
      setTimeout(() => setShowError(false), 8000);
    }
  }

  return (
    <>
      <label className="btn-outline mb-3">Juniors</label>
      <br />
      <small>
        * Please fill in if any of your children/s are living in the same home
      </small>

      <form className="p-0" onSubmit={handleSubmit} method='post' onKeyDown={preventEnter}>
        <div className="row my-5 position-relative">

          {formIds.map((formId) => {
            formKey++;
            if (Object.keys(JuniorsData).length > 0) {
              return (
                <div key={formKey}>
                  {formIds.length > 1 && formId > 1 && (
                    <div className="text-end">
                      <a className="btn btn-primary" onClick={() => handleDeleteForm(formId)}><Icon.Trash3Fill /></a>
                    </div>
                  )}
                  <JuniorsForm
                    formNumber={formId}
                    showDelete={formIds.length > 1}
                    juniorsAllData={JuniorsData}
                    juniorsSetData={setJuniorsData}
                  />
                </div>
              )
            } else {
              return <Bars color="#003da7" ariaLabel="bars-loading" wrapperClass="loadingBars h-100 w-100" visible={true} />
            }
          })
          }

          {formIds.length > 0 && (
            <div className="row mb-3 mb-md-5">
              <a onClick={handleAddForm} className="fw-bold text-decoration-none mainColor mx-3 addBtn" role="button">Add Child +</a>
            </div>
          )}

          <div className="row">
            <div className="submit d-flex justify-content-end mb-1 mb-md-5">
              <button type='submit' className="rounded-pill border-0 main-color px-3 px-md-5 text-white fw-bold py-2">Submit & Continue <i className="bi bi-arrow-right"></i></button>
            </div>
          </div>

        </div>
      </form>
    </>
  );
};

export default Juniors;