var globalVals = {
    loggedIn: false,
    user: null,
    userName: null,
    accessToken: null,
    currentStep: "loginForm",
    preStep: null,
    countries: [
        { name: 'Afghanistan', nationality: 'Afghan', isocode: 'AF', code: '93' },
        { name: 'Albania', nationality: 'Albanian', isocode: 'AL', code: '355' },
        { name: 'Algeria', nationality: 'Algerian', isocode: 'DZ', code: '213' },
        { name: 'Argentina', nationality: 'Argentine', isocode: 'AR', code: '54' },
        { name: 'Australia', nationality: 'Australian', isocode: 'AU', code: '61' },
        { name: 'Austria', nationality: 'Austrian', isocode: 'AT', code: '43' },
        { name: 'Bangladesh', nationality: 'Bangladeshi', isocode: 'BD', code: '880' },
        { name: 'Belgium', nationality: 'Belgian', isocode: 'BE', code: '32' },
        { name: 'Bolivia', nationality: 'Bolivian', isocode: 'BO', code: '975' },
        { name: 'Botswana', nationality: 'Batswana', isocode: 'BW', code: '267' },
        { name: 'Brazil', nationality: 'Brazilian', isocode: 'BR', code: '55' },
        { name: 'Bulgaria', nationality: 'Bulgarian', isocode: 'BG', code: '359' },
        { name: 'Cambodia', nationality: 'Cambodian', isocode: 'KH', code: '855' },
        { name: 'Cameroon', nationality: 'Cameroonian', isocode: 'CM', code: '237' },
        { name: 'Canada', nationality: 'Canadian', isocode: 'CA', code: '1' },
        { name: 'Chile', nationality: 'Chilean', isocode: 'CL', code: '56' },
        { name: 'China', nationality: 'Chinese', isocode: 'CN', code: '86' },
        { name: 'Colombia *', nationality: 'Colombian', isocode: 'CO', code: '57' },
        { name: 'Costa Rica', nationality: 'Costa Rican', isocode: 'CR', code: '506' },
        { name: 'Croatia', nationality: 'Croatian', isocode: 'HR', code: '385' },
        { name: 'Cuba', nationality: 'Cuban', isocode: 'CU', code: '53' },
        { name: 'Czech Republic', nationality: 'Czech', isocode: 'CZ', code: '420' },
        { name: 'Denmark', nationality: 'Danish', isocode: 'DK', code: '45' },
        { name: 'Dominican Republic', nationality: 'Dominican', isocode: 'DO', code: '1' },
        { name: 'Ecuador', nationality: 'Ecuadorian', isocode: 'EC', code: '593' },
        { name: 'Egypt', nationality: 'Egyptian', isocode: 'EG', code: '20' },
        { name: 'El Salvador', nationality: 'Salvadorian', isocode: 'SV', code: '503' },
        { name: 'England', nationality: 'English', isocode: 'SV', code: '503' },
        { name: 'Estonia', nationality: 'Estonian', isocode: 'EE', code: '372' },
        { name: 'Ethiopia', nationality: 'Ethiopian', isocode: 'ET', code: '251' },
        { name: 'Fiji', nationality: 'Fijian', isocode: 'FJ', code: '679' },
        { name: 'Finland', nationality: 'Finnish', isocode: 'FI', code: '358' },
        { name: 'France', nationality: 'French', isocode: 'FR', code: '33' },
        { name: 'Germany', nationality: 'German', isocode: 'DE', code: '49' },
        { name: 'Ghana', nationality: 'Ghanaian', isocode: 'GH', code: '233' },
        { name: 'Greece', nationality: 'Greek', isocode: 'GR', code: '30' },
        { name: 'Guatemala', nationality: 'Guatemalan', isocode: 'GT', code: '502' },
        { name: 'Haiti', nationality: 'Haitian', isocode: 'HT', code: '509' },
        { name: 'Honduras', nationality: 'Honduran', isocode: 'HN', code: '504' },
        { name: 'Hungary', nationality: 'Hungarian', isocode: 'HU', code: '36' },
        { name: 'Iceland', nationality: 'Icelandic', isocode: 'IS', code: '354' },
        { name: 'India', nationality: 'Indian', isocode: 'IN', code: '91' },
        { name: 'Indonesia', nationality: 'Indonesian', isocode: 'ID', code: '62' },
        { name: 'Iran', nationality: 'Iranian', isocode: 'IR', code: '808' },
        { name: 'Iraq', nationality: 'Iraqi', isocode: 'IQ', code: '964' },
        { name: 'Ireland', nationality: 'Irish', isocode: 'IE', code: '353' },
        { name: 'Israel', nationality: 'Israeli', isocode: 'IL', code: '972' },
        { name: 'Italy', nationality: 'Italian', isocode: 'IT', code: '39' },
        { name: 'Jamaica', nationality: 'Jamaican', isocode: 'JM', code: '1' },
        { name: 'Japan', nationality: 'Japanese', isocode: 'JP', code: '81' },
        { name: 'Jordan', nationality: 'Jordanian', isocode: 'JO', code: '962' },
        { name: 'Kenya', nationality: 'Kenyan', isocode: 'KE', code: '254' },
        { name: 'Kuwait', nationality: 'Kuwaiti', isocode: 'KW', code: '965' },
        { name: 'Laos', nationality: 'Lao', isocode: 'LA', code: '856' },
        { name: 'Latvia', nationality: 'Latvian', isocode: 'LV', code: '371' },
        { name: 'Lebanon', nationality: 'Lebanese', isocode: 'LB', code: '961' },
        { name: 'Libya', nationality: 'Libyan', isocode: 'LY', code: '218' },
        { name: 'Lithuania', nationality: 'Lithuanian', isocode: 'LT', code: '370' },
        { name: 'Madagascar', nationality: 'Malagasy', isocode: 'MG', code: '261' },
        { name: 'Malaysia', nationality: 'Malaysian', isocode: 'MY', code: '60' },
        { name: 'Mali', nationality: 'Malian', isocode: 'ML', code: '223' },
        { name: 'Malta', nationality: 'Maltese', isocode: 'MT', code: '356' },
        { name: 'Mexico', nationality: 'Mexican', isocode: 'MX', code: '52' },
        { name: 'Mongolia', nationality: 'Mongolian', isocode: 'MN', code: '976' },
        { name: 'Morocco', nationality: 'Moroccan', isocode: 'MA', code: '212' },
        { name: 'Mozambique', nationality: 'Mozambican', isocode: 'MZ', code: '258' },
        { name: 'Namibia', nationality: 'Namibian', isocode: 'NA', code: '264' },
        { name: 'Nepal', nationality: 'Nepalese', isocode: 'NP', code: '977' },
        { name: 'Netherlands', nationality: 'Dutch', isocode: 'NL', code: '31' },
        { name: 'New Zealand', nationality: 'New Zealand', isocode: 'NZ', code: '64' },
        { name: 'Nicaragua', nationality: 'Nicaraguan', isocode: 'NI', code: '505' },
        { name: 'Nigeria', nationality: 'Nigerian', isocode: 'NG', code: '234' },
        { name: 'Norway', nationality: 'Norwegian', isocode: 'NO', code: '47' },
        { name: 'Pakistan', nationality: 'Pakistani', isocode: 'PK', code: '92' },
        { name: 'Panama', nationality: 'Panamanian', isocode: 'PA', code: '507' },
        { name: 'Paraguay', nationality: 'Paraguayan', isocode: 'PY', code: '595' },
        { name: 'Peru', nationality: 'Peruvian', isocode: 'PE', code: '51' },
        { name: 'Philippines', nationality: 'Philippine', isocode: 'PH', code: '63' },
        { name: 'Poland', nationality: 'Polish', isocode: 'PL', code: '48' },
        { name: 'Portugal', nationality: 'Portuguese', isocode: 'PT', code: '351' },
        { name: 'Romania', nationality: 'Romanian', isocode: 'RO', code: '40' },
        { name: 'Russia', nationality: 'Russian', isocode: 'RU', code: '40' },
        { name: 'Saudi Arabia', nationality: 'Saudi', isocode: 'SA', code: '966' },
        { name: 'Scotland', nationality: 'Scottish', isocode: 'SA', code: '966' },
        { name: 'Senegal', nationality: 'Senegalese', isocode: 'SN', code: '221' },
        { name: 'Serbia', nationality: 'Serbian', isocode: 'RS', code: '381' },
        { name: 'Singapore', nationality: 'Singaporean', isocode: 'SG', code: '65' },
        { name: 'Slovakia', nationality: 'Slovak', isocode: 'SK', code: '421' },
        { name: 'South Africa', nationality: 'South African', isocode: 'ZA', code: '27' },
        { name: 'South Korea', nationality: 'Korean', isocode: 'KR', code: '27' },
        { name: 'Spain', nationality: 'Spanish', isocode: 'ES', code: '34' },
        { name: 'Sri Lanka', nationality: 'Sri Lankan', isocode: 'LK', code: '94' },
        { name: 'Sudan', nationality: 'Sudanese', isocode: 'SD', code: '249' },
        { name: 'Sweden', nationality: 'Swedish', isocode: 'SE', code: '46' },
        { name: 'Switzerland', nationality: 'Swiss', isocode: 'CH', code: '41' },
        { name: 'Syria', nationality: 'Syrian', isocode: 'SY', code: '41' },
        { name: 'Taiwan', nationality: 'Taiwanese', isocode: 'TW', code: '963' },
        { name: 'Tajikistan', nationality: 'Tajikistani', isocode: 'TJ', code: '992' },
        { name: 'Thailand', nationality: 'Thai', isocode: 'TH', code: '66' },
        { name: 'Tonga', nationality: 'Tongan', isocode: 'TO', code: '676' },
        { name: 'Tunisia', nationality: 'Tunisian', isocode: 'TN', code: '216' },
        { name: 'Turkey', nationality: 'Turkish', isocode: 'TR', code: '90' },
        { name: 'Ukraine', nationality: 'Ukrainian', isocode: 'UA', code: '380' },
        { name: 'United Arab Emirates', nationality: 'Emirati', isocode: 'AE', code: '971' },
        { name: 'United Kingdom', nationality: 'British', isocode: 'GB', code: '44' },
        { name: 'United States', nationality: 'American', isocode: 'US', code: '1' },
        { name: 'Uruguay', nationality: 'Uruguayan', isocode: 'UY', code: '598' },
        { name: 'Venezuela', nationality: 'Venezuelan', isocode: 'VE', code: '970' },
        { name: 'Vietnam', nationality: 'Vietnamese', isocode: 'VN', code: '84' },
        { name: 'Wales', nationality: 'Welsh', isocode: 'VN', code: '84' },
        { name: 'Zambia', nationality: 'Zambian', isocode: 'ZM', code: '260' },
        { name: 'Zimbabwe', nationality: 'Zimbabwean', isocode: 'ZW', code: '970' },
    ],
    FavSportOptions: [
        { sport: 'American Football' },
        { sport: 'Archery' },
        { sport: 'Artistic Swimming' },
        { sport: 'Athletics' },
        { sport: 'Athletics Sports' },
        { sport: 'Badminton' },
        { sport: 'Baseball' },
        { sport: 'Basketball' },
        { sport: 'Bowls' },
        { sport: 'Boxing' },
        { sport: 'Canoeing' },
        { sport: 'Commonwealth Games' },
        { sport: 'Cricket' },
        { sport: 'Curling' },
        { sport: 'Cycling' },
        { sport: 'Darts' },
        { sport: 'Diving' },
        { sport: 'Equestrian' },
        { sport: 'Fencing' },
        { sport: 'Football' },
        { sport: 'Formula 1' },
        { sport: 'Gaelic Games' },
        { sport: 'Golf' },
        { sport: 'Gymnastics' },
        { sport: 'Handball' },
        { sport: 'Hockey' },
        { sport: 'Horse Racing' },
        { sport: 'Ice Hockey' },
        { sport: 'Judo' },
        { sport: 'Karate' },
        { sport: 'Mixed Martial Arts' },
        { sport: 'Modern Pentathlon' },
        { sport: 'Modern Pentathlon ' },
        { sport: 'Motorsport' },
        { sport: 'Netball' },
        { sport: 'Padel' },
        { sport: 'Rackets' },
        { sport: 'Rowing' },
        { sport: 'Rugby League' },
        { sport: 'Rugby Union' },
        { sport: 'Sailing' },
        { sport: 'Shooting' },
        { sport: 'Skateboarding' },
        { sport: 'Snooker' },
        { sport: 'Softball' },
        { sport: 'Sport Climbing' },
        { sport: 'Squash' },
        { sport: 'Surfing' },
        { sport: 'Swimming' },
        { sport: 'Table Tennis' },
        { sport: 'Taekwondo' },
        { sport: 'Tennis' },
        { sport: 'Triathlon' },
        { sport: 'Volleyball' },
        { sport: 'Water Polo' },
        { sport: 'Weightlifting' },
        { sport: 'Wrestling' },
        { sport: 'Others' }
    ],
    InterestHobbiesOptions: [
        { intHob: 'Acting' },
        { intHob: 'Baking' },
        { intHob: 'Bird watching' },
        { intHob: 'Blogging' },
        { intHob: 'Board games' },
        { intHob: 'Camping' },
        { intHob: 'Card games' },
        { intHob: 'Ceramics' },
        { intHob: 'Chess' },
        { intHob: 'Climbing' },
        { intHob: 'Coloring' },
        { intHob: 'Cooking' },
        { intHob: 'Crafts' },
        { intHob: 'Creative writing' },
        { intHob: 'Crocheting' },
        { intHob: 'Crossword puzzles' },
        { intHob: 'Dance' },
        { intHob: 'Darts' },
        { intHob: 'Decorating' },
        { intHob: 'Drawing' },
        { intHob: 'Embroidery' },
        { intHob: 'Engraving' },
        { intHob: 'Fashion design' },
        { intHob: 'Feng shui decorating' },
        { intHob: 'Filmmaking' },
        { intHob: 'Fishing' },
        { intHob: 'Flower arranging' },
        { intHob: 'Language learning' },
        { intHob: 'Gardening' },
        { intHob: 'Graphic design' },
        { intHob: 'Hairstyle' },
        { intHob: 'Hiking' },
        { intHob: 'Home improvement' },
        { intHob: 'Jewelry making' },
        { intHob: 'Jigsaw puzzles' },
        { intHob: 'Journaling' },
        { intHob: 'Knitting' },
        { intHob: 'Knitting' },
        { intHob: 'Leather crafting' },
        { intHob: 'Listening to podcasts' },
        { intHob: 'Livestreaming' },
        { intHob: 'Macrame' },
        { intHob: 'Makeup' },
        { intHob: 'Meditation' },
        { intHob: 'Music' },
        { intHob: 'Origami' },
        { intHob: 'Painting' },
        { intHob: 'Painting' },
        { intHob: 'Pet care' },
        { intHob: 'Photography' },
        { intHob: 'Plastic art' },
        { intHob: 'Poetry' },
        { intHob: 'Pottery' },
        { intHob: 'Public speaking' },
        { intHob: 'Puzzle' },
        { intHob: 'Quilting' },
        { intHob: 'Reading' },
        { intHob: 'Reading' },
        { intHob: 'Safari' },
        { intHob: 'Sailing' },
        { intHob: 'Sandboarding' },
        { intHob: 'Scuba diving' },
        { intHob: 'Sculpting' },
        { intHob: 'Sewing' },
        { intHob: 'Shoemaking' },
        { intHob: 'Shooting' },
        { intHob: 'Shopping' },
        { intHob: 'Singing' },
        { intHob: 'Others' }
    ],
    MemberShipOptions: [
        { clubsMemberShip: 'None' },
        { clubsMemberShip: 'MV Club East' },
        { clubsMemberShip: 'MV Club West' },
        { clubsMemberShip: '6th of October City Club' },
        { clubsMemberShip: 'Aerosport Club' },
        { clubsMemberShip: 'Air Defense House' },
        { clubsMemberShip: 'El Shams SC' },
        { clubsMemberShip: 'Al Ahly Club' },
        { clubsMemberShip: 'Al Masry' },
        { clubsMemberShip: 'Al Zohour ' },
        { clubsMemberShip: 'Alexandria Sporting Club' },
        { clubsMemberShip: 'Al Rehab Sports Club' },
        { clubsMemberShip: 'Arab Contractors Club' },
        { clubsMemberShip: 'Arabella Country Club' },
        { clubsMemberShip: 'Armor House' },
        { clubsMemberShip: 'Aviation Club' },
        { clubsMemberShip: 'Cars Club' },
        { clubsMemberShip: 'Diplomatic Club' },
        { clubsMemberShip: 'Dream Land Sporting Club' },
        { clubsMemberShip: 'Egyptian Shooting Club' },
        { clubsMemberShip: 'El Gouna FC Club' },
        { clubsMemberShip: 'El Shams Sporting Club' },
        { clubsMemberShip: 'Elite International Soccer' },
        { clubsMemberShip: 'Enppi Sporting Club' },
        { clubsMemberShip: 'Forest Sporting Club' },
        { clubsMemberShip: 'Gezira Sporting Club' },
        { clubsMemberShip: 'Greek Club' },
        { clubsMemberShip: 'Heliolido Sporting Club' },
        { clubsMemberShip: 'Heliopolis Sporting Club' },
        { clubsMemberShip: 'Horse Racing Club' },
        { clubsMemberShip: 'Hurghada Sporting Club' },
        { clubsMemberShip: 'Itehad Club' },
        { clubsMemberShip: 'Katameya Heights Golf & Tennis Resort' },
        { clubsMemberShip: 'Katameya Sporting Club' },
        { clubsMemberShip: 'Life Sports Club' },
        { clubsMemberShip: 'Maadi Club' },
        { clubsMemberShip: 'Madinaty Club' },
        { clubsMemberShip: 'Alnasr Sporting Club' },
        { clubsMemberShip: 'Nasr City Sporting Club' },
        { clubsMemberShip: 'New Cairo Club' },
        { clubsMemberShip: 'Petrosport Sporting Club' },
        { clubsMemberShip: 'Platinum Club' },
        { clubsMemberShip: 'Republican Guards Club' },
        { clubsMemberShip: 'Royal Sports Club' },
        { clubsMemberShip: 'Smart Village Club' },
        { clubsMemberShip: 'Smash Sporting Club' },
        { clubsMemberShip: 'Smouha Club' },
        { clubsMemberShip: 'Solaris Club' },
        { clubsMemberShip: 'Tersana Sporting Club' },
        { clubsMemberShip: 'Wadi Degla Club' },
        { clubsMemberShip: 'Yacht Club' },
        { clubsMemberShip: 'Zamalek Club' },
        { clubsMemberShip: 'Egyptian Rowing Club' },
        { clubsMemberShip: 'Tawfikiya Tennis Club' },
        { clubsMemberShip: 'Others' }
    ],
    jobTitlesOptions: [
        { jobTitle: 'House wife' },
        { jobTitle: 'Retired' },
        { jobTitle: 'Account Manager' },
        { jobTitle: 'Accountant' },
        { jobTitle: 'Accounting Director' },
        { jobTitle: 'Actor' },
        { jobTitle: 'Addiction Counselor' },
        { jobTitle: 'Administrative Manager' },
        { jobTitle: 'Application Developer' },
        { jobTitle: 'Architect' },
        { jobTitle: 'Artificial Intelligence Engineer' },
        { jobTitle: 'Artist' },
        { jobTitle: 'Astronomer' },
        { jobTitle: 'Athletic' },
        { jobTitle: 'Atmospheric Scientist' },
        { jobTitle: 'Attorney' },
        { jobTitle: 'Auditor' },
        { jobTitle: 'Benefits Manager' },
        { jobTitle: 'Biologist' },
        { jobTitle: 'Board Member' },
        { jobTitle: 'Branch Manager' },
        { jobTitle: 'Brand Manager' },
        { jobTitle: 'Brand Strategist' },
        { jobTitle: 'Business Development ' },
        { jobTitle: 'Business Manager' },
        { jobTitle: 'CCO - Chief Customer Officer' },
        { jobTitle: 'CDO - Chief Data Officer' },
        { jobTitle: 'CEO' },
        { jobTitle: 'CEO - Chief Executive Officer' },
        { jobTitle: 'CFO - Chief Financial Officer' },
        { jobTitle: 'Chemist' },
        { jobTitle: 'Chief' },
        { jobTitle: 'Chief Information Officer (CIO)' },
        { jobTitle: 'Chief Technology Officer (CTO)' },
        { jobTitle: 'CHRO - Chief Human Resources Officer' },
        { jobTitle: 'Civil Engineer' },
        { jobTitle: 'Cloud Architect' },
        { jobTitle: 'CMO - Chief Marketing Officer' },
        { jobTitle: 'Coach' },
        { jobTitle: 'Columnist' },
        { jobTitle: 'Communications Director' },
        { jobTitle: 'Communications Manager' },
        { jobTitle: 'Computer Animator' },
        { jobTitle: 'Computer Programmer' },
        { jobTitle: 'Computer Scientist' },
        { jobTitle: 'Content Creator' },
        { jobTitle: 'Content Marketing Manager' },
        { jobTitle: 'Content Strategist' },
        { jobTitle: 'Continuous Improvement Consultant' },
        { jobTitle: 'Continuous Improvement Lead' },
        { jobTitle: 'Controller' },
        { jobTitle: 'COO - Chief Operating Officer' },
        { jobTitle: 'Coordinator' },
        { jobTitle: 'Copy Editor' },
        { jobTitle: 'Copywriter' },
        { jobTitle: 'Counselor' },
        { jobTitle: 'CPO - Chief Product Officer' },
        { jobTitle: 'Credit Counselor' },
        { jobTitle: 'Cruise Director' },
        { jobTitle: 'CTO - Chief Technology Officer' },
        { jobTitle: 'Customer Service Manager' },
        { jobTitle: 'Data Analyst' },
        { jobTitle: 'Dentist' },
        { jobTitle: 'Deputy Manager' },
        { jobTitle: 'Dermatologist' },
        { jobTitle: 'DevOps Engineer' },
        { jobTitle: 'Digital Marketing Manager' },
        { jobTitle: 'Director' },
        { jobTitle: 'Director of Maintenance' },
        { jobTitle: 'Doctor' },
        { jobTitle: 'Economist' },
        { jobTitle: 'Electrical Engineer' },
        { jobTitle: 'Engineer' },
        { jobTitle: 'Entrepreneur' },
        { jobTitle: 'Event Planner' },
        { jobTitle: 'Executive Assistant' },
        { jobTitle: 'Film Critic' },
        { jobTitle: 'Finance Director' },
        { jobTitle: 'Finance Manager' },
        { jobTitle: 'Financial Analyst' },
        { jobTitle: 'Financial Planner' },
        { jobTitle: 'Flight Attendant' },
        { jobTitle: 'Founder' },
        { jobTitle: 'Geologist' },
        { jobTitle: 'Graphic Designer' },
        { jobTitle: 'Hair Stylist' },
        { jobTitle: 'Head' },
        { jobTitle: 'Hotel Manager' },
        { jobTitle: 'HR Manager' },
        { jobTitle: 'Influencer' },
        { jobTitle: 'Interior Designer' },
        { jobTitle: 'IT Manager' },
        { jobTitle: 'Journalist' },
        { jobTitle: 'Judge' },
        { jobTitle: 'Lead' },
        { jobTitle: 'Life Coach' },
        { jobTitle: 'Maintenance Engineer' },
        { jobTitle: 'Makeup Artist' },
        { jobTitle: 'Manager' },
        { jobTitle: 'Managing Partner' },
        { jobTitle: 'Market Researcher' },
        { jobTitle: 'Marketing Consultant' },
        { jobTitle: 'Marketing Director' },
        { jobTitle: 'Marketing Manager' },
        { jobTitle: 'Marketing Research Analyst' },
        { jobTitle: 'Marketing Specialist' },
        { jobTitle: 'Marriage Councelor' },
        { jobTitle: 'Massage Therapy' },
        { jobTitle: 'Mathematician' },
        { jobTitle: 'Mechanical Engineer' },
        { jobTitle: 'Media Buyer' },
        { jobTitle: 'Mental Health Counselor' },
        { jobTitle: 'Mentor' },
        { jobTitle: 'Mining Engineer' },
        { jobTitle: 'Molecular Scientist' },
        { jobTitle: 'Motion Picture Director' },
        { jobTitle: 'Music Producer' },
        { jobTitle: 'Musician' },
        { jobTitle: 'Network Administrator' },
        { jobTitle: 'Novelist/Writer' },
        { jobTitle: 'Nuclear Engineer' },
        { jobTitle: 'Office Manager' },
        { jobTitle: 'Operations Director' },
        { jobTitle: 'Operations Manager' },
        { jobTitle: 'Owner' },
        { jobTitle: 'Personal Trainer' },
        { jobTitle: 'Petroleum Engineer' },
        { jobTitle: 'Pharmacist' },
        { jobTitle: 'Photographer' },
        { jobTitle: 'Physicist' },
        { jobTitle: 'Pilot' },
        { jobTitle: 'Plant Engineer' },
        { jobTitle: 'Political Scientist' },
        { jobTitle: 'President' },
        { jobTitle: 'Principal' },
        { jobTitle: 'Product Manager' },
        { jobTitle: 'Production Engineer' },
        { jobTitle: 'Professor' },
        { jobTitle: 'Program Manager' },
        { jobTitle: 'Project Manager' },
        { jobTitle: 'Public figure' },
        { jobTitle: 'Public Relations' },
        { jobTitle: 'Quality Control ' },
        { jobTitle: 'Real Estate Broker' },
        { jobTitle: 'Restaurant Chain Executive' },
        { jobTitle: 'Restaurant Manager' },
        { jobTitle: 'Risk Manager' },
        { jobTitle: 'Safety Engineer' },
        { jobTitle: 'Sales Manager' },
        { jobTitle: 'Scrum Master' },
        { jobTitle: 'Shareholder' },
        { jobTitle: 'Singer' },
        { jobTitle: 'Sociologist' },
        { jobTitle: 'Software Engineer' },
        { jobTitle: 'Sound Engineer' },
        { jobTitle: 'Spa Manager' },
        { jobTitle: 'Specialist' },
        { jobTitle: 'SQL Developer' },
        { jobTitle: 'Sr Manager' },
        { jobTitle: 'Sr Supervisor' },
        { jobTitle: 'Sr. Specialist' },
        { jobTitle: 'Supervisor' },
        { jobTitle: 'Teacher' },
        { jobTitle: 'Team Leader' },
        { jobTitle: 'Therapist' },
        { jobTitle: 'Translator' },
        { jobTitle: 'UI Developer' },
        { jobTitle: 'UX Designer' },
        { jobTitle: 'Web Designer' },
        { jobTitle: 'Web Developer' },
        { jobTitle: 'Wedding Planner' },
        { jobTitle: 'Yoga Instructor' },
        { jobTitle: 'Others' }
    ],
    universitiesNameOptions: [
        { university: 'Ahram Canadian University' },
        { university: 'Ain Shams University' },
        { university: 'Al Azhar University' },
        { university: 'AlAlamein International University' },
        { university: 'Alexandria University' },
        { university: 'American University in Cairo' },
        { university: 'Arab Academy for Science, Technology and Maritime Transport' },
        { university: 'Arab Open University' },
        { university: 'Arish University' },
        { university: 'Assiut University' },
        { university: 'Aswan University' },
        { university: 'Badr University in Cairo ' },
        { university: 'Banha University' },
        { university: 'Beni Suef University' },
        { university: 'British University in Egypt' },
        { university: 'Cairo University' },
        { university: 'Canadian International College (CIC)' },
        { university: 'Damanhour University' },
        { university: 'Damietta University' },
        { university: 'Delta University for Science and Technology' },
        { university: 'Deraya University' },
        { university: 'Ecole Supérieure Libre des Sciences Commerciales Appliquées (ESLSCA)' },
        { university: 'Egypt Japan University of Science & Technology (EJUST)' },
        { university: 'Egypt University of Informatics' },
        { university: 'Egyptian Chinese University (ECU)' },
        { university: 'Egyptian Russian University (ERU)' },
        { university: 'El Asher University' },
        { university: 'El Shorouk Academy' },
        { university: 'Fayoum University' },
        { university: 'French University of Egypt' },
        { university: 'Future University in Egypt (FUE)' },
        { university: 'Galala University (GU)' },
        { university: 'German University in Cairo (GUC)' },
        { university: 'Heliopolis University for Sustainable Development' },
        { university: 'Helwan University' },
        { university: 'Higher Institute of Computer and Business Administration in Zarqa' },
        { university: 'Horus University' },
        { university: 'Kafr El Sheikh University' },
        { university: 'King Salman International University (KSIU)' },
        { university: 'L\'université Senghor À Alexandrie' },
        { university: 'Luxor University' },
        { university: 'Mansoura University' },
        { university: 'Matrouh University' },
        { university: 'May University in Cairo' },
        { university: 'Menoufia University' },
        { university: 'Merit University' },
        { university: 'Military Technical College (MTC)' },
        { university: 'Minia University' },
        { university: 'Misr International University (MIU)' },
        { university: 'Misr University for Science and Technology (MUST)' },
        { university: 'Modern Academy For Engineering & Technology' },
        { university: 'Modern University for Technology and Information (MTI)' },
        { university: 'Nahda University in Beni Suef' },
        { university: 'New Giza University' },
        { university: 'New Mansoura University' },
        { university: 'New Valley University (NVU)' },
        { university: 'Nile University' },
        { university: 'October 6 University' },
        { university: 'October University for Modern Sciences and Arts (MSA)' },
        { university: 'Pharos University' },
        { university: 'Port Said University' },
        { university: 'Ryerson University Cairo' },
        { university: 'Sadat Academy for Management Sciences' },
        { university: 'Sinai University' },
        { university: 'Sohag University' },
        { university: 'South Valley University' },
        { university: 'Sphinx University' },
        { university: 'Suez Canal University' },
        { university: 'Tanta University' },
        { university: 'The German International University (GIU)' },
        { university: 'Toronto Metropolitan University Cairo' },
        { university: 'Universite Francaise d\'Egypte' },
        { university: 'University of Central Lancashire' },
        { university: 'University of East London' },
        { university: 'University of Hertfordshire' },
        { university: 'University of London' },
        { university: 'University of Prince Edward Island' },
        { university: 'University of Sadat City' },
        { university: 'Zagazig University' },
        { university: 'Zewail City of Science and Technology' },
        { university: 'Other' },
    ],
    schoolNameOptions: [
        { school: 'Agyal Integrated Language Schools ' },
        { school: 'Akhnaton Egyptian Language School ' },
        { school: 'Al Afak Al Gadeda International School ' },
        { school: 'Al Alson Language School ' },
        { school: 'Al Andalus International School (AAIS) ' },
        { school: 'Al Bashaer International School ' },
        { school: 'Al Eman Language Schools ' },
        { school: 'Al Farouk Islamic Language School ' },
        { school: 'Al Hoda Azhar Language School ' },
        { school: 'Al Karma Language School ' },
        { school: 'Al Khalil Language Schools ' },
        { school: 'Al Retaj Language School ' },
        { school: 'Al Yasser Language Schools ' },
        { school: 'Alexandria International Academy (AIA) ' },
        { school: 'Alexandria International School (AIS) ' },
        { school: 'Alexandria Language School (ALS) ' },
        { school: 'Al-Hossam Integrated School (HIS) ' },
        { school: 'American International School in Egypt (AIS) ' },
        { school: 'American School of Alexandria ' },
        { school: 'Anglo American School - 6th October Branch ' },
        { school: 'Armenian Catholic Sisters School ' },
        { school: 'Aspire International school ' },
        { school: 'BBC International School ' },
        { school: 'Bedayia International School ' },
        { school: 'Beverly Hills School Egypt ' },
        { school: 'Bluebells International School ' },
        { school: 'Brilliance Language School ' },
        { school: 'British Columbia Canadian International School ' },
        { school: 'British International College of Cairo ' },
        { school: 'British Modern School ' },
        { school: 'British Ramses School ' },
        { school: 'British School of Alexandria - BSA ' },
        { school: 'Cairo American College (CAC) ' },
        { school: 'Cairo British School - CBS ' },
        { school: 'Cairo English School ' },
        { school: 'Cairo Modern International School - CMIS ' },
        { school: 'Canadian International School of Egypt ' },
        { school: 'Capital International School - CIS ' },
        { school: 'Carleton College ' },
        { school: 'City International School ' },
        { school: 'Collège Saint Marc ' },
        { school: 'Concordia Lycée International Français en Egypte -LIFE ' },
        { school: 'Continental Palace School - CPS ' },
        { school: 'Cornell International School ' },
        { school: 'Creative International School ' },
        { school: 'Dar El Tarbiah School ' },
        { school: 'Delta International Language Schools ' },
        { school: 'Deutsche Evangelische Oberschule Kairo (DEO) ' },
        { school: 'Deutsche Schule Der Borromaerinnen Kairo - DSBK ' },
        { school: 'Deutsche Schule Hurghada ' },
        { school: 'Dover American International School ' },
        { school: 'Dr Nermien Ismail Language Schools - NIS ' },
        { school: 'Dream International Schools ' },
        { school: 'Ecole Oasis Internationale ' },
        { school: 'Egypt British International School ' },
        { school: 'Egypt Dream Language School ' },
        { school: 'Egypt Integrated Schools ' },
        { school: 'Egypt Modern School ' },
        { school: 'Egyptian American School ' },
        { school: 'Egyptian English Language School ' },
        { school: 'Egyptian Language School ' },
        { school: 'El Alsson British & American International School ' },
        { school: 'El Fouad International School ' },
        { school: 'El Gouna International School ' },
        { school: 'El Manhal Private School ' },
        { school: 'El Massria Integrated Language School ' },
        { school: 'El Quds International School ' },
        { school: 'El Rowad College ' },
        { school: 'El Zahraa American School ' },
        { school: 'ELM International School ' },
        { school: 'Elwy Language Schools ' },
        { school: 'Ethos International School ' },
        { school: 'Europa Schule Kairo ' },
        { school: 'Europa Schule Neu Kairo - ESNK ' },
        { school: 'European Schools of Alexandria ' },
        { school: 'Evolution International School ' },
        { school: 'Forsan international school ' },
        { school: 'Future International Schools ' },
        { school: 'Future Rise Language School ' },
        { school: 'Gateway International Montessori School ' },
        { school: 'GEMS Academy Alexandria - GAA ' },
        { school: 'GEMS British International School Madinaty ' },
        { school: 'GEMS British School Al Rehab, Cairo ' },
        { school: 'Genesis International School ' },
        { school: 'Gheriany International Schools ' },
        { school: 'Global Paradigm International School - GPIS ' },
        { school: 'Grand Language School ' },
        { school: 'Greatness Language School ' },
        { school: 'Green Heights International School ' },
        { school: 'Green Heights Language School ' },
        { school: 'Green Land International School - GPIS ' },
        { school: 'Green Valley School - GVS ' },
        { school: 'Gulf English School Cairo - GES ' },
        { school: 'Haileybury Cairo School ' },
        { school: 'Hayah International Academy ' },
        { school: 'Heritage International School ' },
        { school: 'Infinite International School ' },
        { school: 'Inspire Language Schools ' },
        { school: 'Integrated Modern School ' },
        { school: 'Integrated Thebes American College in Cairo - ITACC ' },
        { school: 'Integrated Thebes International School ' },
        { school: 'International American School ' },
        { school: 'International British School of Alexandria ' },
        { school: 'International Modern School Sayed Galal ' },
        { school: 'Irish School Cairo ' },
        { school: 'IVY International Schools ' },
        { school: 'Jana Dan International School ' },
        { school: 'Kent College Egypt - KCE (West Cairo) ' },
        { school: 'Kipling School ' },
        { school: 'La Rose De Lisieux School ' },
        { school: 'Leaders International College ' },
        { school: 'Leaders Language School ' },
        { school: 'Liverpool International School - LIS ' },
        { school: 'Lycee El Horreya School ' },
        { school: 'Lycee International Balzac ' },
        { school: 'Lycee International Nefertari School ' },
        { school: 'Maadi British International School ' },
        { school: 'Maadi Community School ' },
        { school: 'Maadi Narmer School - MNS ' },
        { school: 'Madinaty Integrated Language Schools ' },
        { school: 'Madinaty Language School ' },
        { school: 'Maharat Super Global School - MSG ' },
        { school: 'Majesty International School - MIS ' },
        { school: 'Malvern College Egypt ' },
        { school: 'Manarat Al-Mostaqbal Schools ' },
        { school: 'Manarat El Maadi Language School ' },
        { school: 'Manaret Heliopolis International School ' },
        { school: 'Manchester International School ' },
        { school: 'Manhattan Schools Egypt ' },
        { school: 'Manor House International School ' },
        { school: 'Masters Language School ' },
        { school: 'Mavericks International School ' },
        { school: 'Merryland International School - MIS ' },
        { school: 'Metropolitan School Cairo ' },
        { school: 'Misr American College - MAC ' },
        { school: 'Misr Language School - MLS ' },
        { school: 'Modern Education Schools ' },
        { school: 'Modern English School ' },
        { school: 'Modern School of Egypt 2000 - MSE ' },
        { school: 'Mokattam Language and International School ' },
        { school: 'Narmer American College - NAC ' },
        { school: 'Nefertari International School - NIS ' },
        { school: 'New Cairo British International School ' },
        { school: 'New Discovery International School ' },
        { school: 'New Generation International Schools ' },
        { school: 'New Horizon International School ' },
        { school: 'New Ramses College ' },
        { school: 'New Vision International Schools ' },
        { school: 'Nile International College - NIC ' },
        { school: 'Nile Modern Language Schools ' },
        { school: 'Nile Union Academy ' },
        { school: 'Notion International School ' },
        { school: 'Nozha Language Schools ' },
        { school: 'Orman Academy School ' },
        { school: 'Orouba Language School ' },
        { school: 'Own Heliopolis Language School ' },
        { school: 'Pakistan International School ' },
        { school: 'Pioneers International School ' },
        { school: 'Pioneers Language School ' },
        { school: 'Port Said International Schools ' },
        { school: 'Prime International School ' },
        { school: 'Princeton International School ' },
        { school: 'Qaitbay International School (QLS) ' },
        { school: 'Rahn Schulen Kairo ' },
        { school: 'Rajac Language Schools ' },
        { school: 'Ramsis Language Schools ' },
        { school: 'Renaissance International School of Egypt - RISE ' },
        { school: 'Repton Cairo ' },
        { school: 'Riada American School ' },
        { school: 'Royal House Language School ' },
        { school: 'Royal International Language Schools ' },
        { school: 'Sahara International School ' },
        { school: 'Sahara Language School ' },
        { school: 'Sahara New Capital International School ' },
        { school: "Saint Clare's College " },
        { school: "Saint George's College " },
        { school: 'Sakkara Language School ' },
        { school: 'Sakr Immersion School ' },
        { school: 'Salahaldin International School ' },
        { school: 'Sama International School ' },
        { school: 'Schutz American School ' },
        { school: 'Sharm International British School ' },
        { school: 'Sheraton Heliopolis School ' },
        { school: 'Smart City International School ' },
        { school: 'Smart International Schools ' },
        { school: 'Solaimaneyah International Schools ' },
        { school: 'Sphinx International School ' },
        { school: 'St John American School ' },
        { school: "St Joseph's American School " },
        { school: "St Peter's School " },
        { school: 'St. Fatima Language School ' },
        { school: 'Suad Kafafi International Language Learning School - SKILLS ' },
        { school: 'Summits International Schools ' },
        { school: 'Sun of Glory International School ' },
        { school: 'Sun of Knowledge British International School ' },
        { school: 'Taymour English School ' },
        { school: 'The American School of Alexandria - ASA ' },
        { school: 'The British International School, Cairo ' },
        { school: 'The British School In Cairo ' },
        { school: 'The British School of Egypt ' },
        { school: 'The Continental School of Cairo ' },
        { school: 'The International School of Egypt ISE ' },
        { school: 'The International School of Elite Education ' },
        { school: 'The International Schools of Choueifat ' },
        { school: 'The International Schools of Kenana - American Division ' },
        { school: 'The Royal College International School RCIS ' },
        { school: 'Ecole de L’avenir' },
        { school: 'Others' }
    ],
    disabilitiesOptions: [
        { disability: 'Learning disabilities' },
        { disability: 'Intellectual disability' },
        { disability: 'Hearing impairment' },
        { disability: 'Cerebral palsy' },
        { disability: 'Physical disability' },
        { disability: 'Vision impairment' },
        { disability: 'Autistic Spectrum Disorders' },
        { disability: 'Deafblindness' },
        { disability: 'Developmental disability' },
        { disability: 'Multiple disabilities' },
        { disability: 'Dyslexia' },
        { disability: 'Traumatic brain injury' },
        { disability: 'Attention deficit hyperactivity disorder' },
        { disability: 'Mental disorder' },
        { disability: 'Multiple sclerosis' },
        { disability: 'Epilepsy' },
        { disability: 'Muscular dystrophy' },
        { disability: 'Neurological disorder' },
        { disability: 'Down syndrome' },
        { disability: 'Dyscalculia' },
        { disability: 'Stroke' },
        { disability: 'Spinal cord injury' },
        { disability: 'Blindness' },
        { disability: 'Bipolar disorder' },
        { disability: 'Others' }
    ],
    carTypesOptions: [
        { type: 'Convertible' },
        { type: 'Coupe' },
        { type: 'Crossover' },
        { type: 'Grand Tourer' },
        { type: 'Hatchback' },
        { type: 'Minivan' },
        { type: 'Pickup Truck' },
        { type: 'Sedan' },
        { type: 'Sports Car' },
        { type: 'SUV' },
        { type: 'Van' },
        { type: 'Others' }
    ],
    carModelsOptions: [
        { model: 'Alfa Romeo' },
        { model: 'Audi' },
        { model: 'BAIC' },
        { model: 'BMW' },
        { model: 'BYD' },
        { model: 'Bestune' },
        { model: 'Brilliance' },
        { model: 'Changan' },
        { model: 'Chery' },
        { model: 'Chevrolet' },
        { model: 'Chrysler' },
        { model: 'Citroen' },
        { model: 'Cupra ' },
        { model: 'DS' },
        { model: 'Dongfeng' },
        { model: 'Fiat' },
        { model: 'Ford' },
        { model: 'Forthing' },
        { model: 'Geely' },
        { model: 'Haval' },
        { model: 'Honda' },
        { model: 'Hyundai' },
        { model: 'JAC' },
        { model: 'JETOUR' },
        { model: 'Jaguar' },
        { model: 'Jeep' },
        { model: 'Kaiyi' },
        { model: 'Kia' },
        { model: 'Lada' },
        { model: 'Land Rover' },
        { model: 'Lexus' },
        { model: 'MG' },
        { model: 'Maserati' },
        { model: 'Mazda' },
        { model: 'Mercedes' },
        { model: 'Mini ' },
        { model: 'Mitsubishi' },
        { model: 'Nissan' },
        { model: 'Opel' },
        { model: 'Peugeot' },
        { model: 'Porsche' },
        { model: 'Proton' },
        { model: 'Range Rover' },
        { model: 'Renault' },
        { model: 'Seat' },
        { model: 'Skoda' },
        { model: 'SsangYong' },
        { model: 'Subaru' },
        { model: 'Suzuki' },
        { model: 'Toyota' },
        { model: 'Volkswagen' },
        { model: 'Volvo' },
        { model: 'Others' }
    ],
    servicesOptions: [
        { service: 'Cleaning' },
        { service: 'Door Issue' },
        { service: 'Electricity' },
        { service: 'Elevator' },
        { service: 'Facilities' },
        { service: 'Flyer Distribution ' },
        { service: 'Intercome problems' },
        { service: 'Landscape' },
        { service: 'Paid Services' },
        { service: 'Pest Control' },
        { service: 'Plumbing' },
        { service: 'Power sockets' },
        { service: 'Security' },
        { service: 'Utilities' },
        { service: 'Waste Collection' },
        { service: 'Water' },
        { service: 'Others' }
    ]
};

export default globalVals;