import { Button } from "react-bootstrap";

const StepOne = () => {

  return (
    <div className="text-center mt-5 pt-5">
      <div className="row mb-5 justify-content-center">
        <div className="col-12">
          <h2 className="heading text-capitalize text-center mb-1"><strong>Pleasure to Meet the Family</strong></h2>
          <br />
          <h4 className="fw-light mx-auto w-75 fst-italic">Nothing says "home" better than a personalized experience. We'd love to know more about your preferences and your family's to make sure all of your needs are met and your aspirations are lived up to.</h4>
          <br />
          <Button onClick={() => window.location.href = "/family-info"} className="rounded-pill main-color px-5 text-white fw-bold mb-3 mb-md-5"><h5 className="mb-0"><b>Go <i className="bi bi-arrow-right"></i></b></h5></Button>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
