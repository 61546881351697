import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppNav from "./AppNav";
import globalVals from './globalVals';
import { encryptData, decryptData } from './http/encrypt';

function App() {

  if (!(sessionStorage.hasOwnProperty("CDC_SESSION_ID"))) {
    sessionStorage.setItem("CDC_SESSION_ID", encryptData(`{"session in empty"}`));
  }

  const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

  globalVals.userName = session.userName;
  globalVals.user = session.user;
  globalVals.loggedIn = session.loggedIn;
  globalVals.currentStep = session.currentStep;

  const locationInfo = useLocation().pathname;

  useEffect(() => {
    if (locationInfo != "/") {
      if (!(globalVals.loggedIn && globalVals.userName) && locationInfo != "/login") {
        window.location.href = "/login";
        return;
      } else {
        if (locationInfo === "/login" && globalVals.loggedIn) {
          window.location.href = "/";
          return;
        } else {
          if (globalVals.currentStep && globalVals.currentStep === "stepOneForm" && locationInfo != "/pleasure-to-meet-you") {
            window.location.href = "/pleasure-to-meet-you";
            return;
          } else if (globalVals.currentStep && globalVals.currentStep === "stepTwoForm" && locationInfo != "/eager-to-know-better") {
            window.location.href = "/eager-to-know-better";
            return;
          } else if (globalVals.currentStep && globalVals.currentStep === "stepThreeForm" && locationInfo != "/family-info") {
            window.location.href = "/family-info";
            return;
          } else if (globalVals.currentStep && globalVals.currentStep === "welcomeBack" && locationInfo != "/welcome-back") {
            window.location.href = "/welcome-back";
            return;
          }
        }
      }
    }
  }, null);

  return (
    <>
      <AppNav />
    </>
  );
}

export default App;
