import React, { useState, useContext, useEffect } from 'react';
import { Bars } from 'react-loader-spinner';
import { customerContext } from '../http/axios';
import globalVals from '../globalVals';
import { encryptData, decryptData } from '../http/encrypt';
import "../index.css";

const Login = () => {
  const { user, getOTP } = useContext(customerContext);
  // const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNum] = useState('');
  const [otpToken, setotpToken] = useState('');
  const [showError, setShowError] = useState(false);
  const { error, isLoading, stepToTake } = user;
  let stepToGo = "loginForm";

  if (stepToTake !== undefined) {
    stepToGo = stepToTake;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (stepToGo === "loginForm") {
      // getOTP(email, phoneNumber);
      getOTP(phoneNumber);
    } else {
      const session = decryptData(JSON.parse(sessionStorage.getItem("CDC_SESSION_ID")));

      if (otpToken === session.accessToken) {
        let currentStepToGo = "stepOneForm";

        switch (parseInt(session.webStepNum)) {
          case 1:
            currentStepToGo = "stepOneForm";
            break;
          case 2:
            currentStepToGo = "stepTwoForm";
            break;
          case 3:
            currentStepToGo = "stepThreeForm";
            break;
          case 4:
            currentStepToGo = "childsInfo";
            break;
          case 5:
            currentStepToGo = "parentsInfo";
            break;
          case 6:
            currentStepToGo = "otherInfo";
            break;
          default:
            currentStepToGo = "stepOneForm"
            if (session.webStepNum > 6) {
              currentStepToGo = "welcomeBack";
            }
            break;
        }

        const newSession = {
          userName: session.userFisrtName,
          user: session.userSecretKey,
          loggedIn: true,
          currentStep: currentStepToGo,
          webStepNum: session.webStepNum
        };
        sessionStorage.setItem("CDC_SESSION_ID", encryptData(newSession));
        return window.location.href = "/";
      } else {
        setShowError(true);
        setTimeout(() => setShowError(false), 5000);
      }
    }
  }

  useEffect(() => {
    if (error) {
      setShowError(true);
      setTimeout(() => setShowError(false), 5000);
    }
  });

  return (
    <div className="main-container container mb-5">
      <div className="row align-items-center justify-content-center">
        <div className=" col-lg-7 col-md-12 col-sm-10 p-2 p-md-5 justify-content-center justify-content-md-start">
          <h2 className="welcome-text m-3 mb-5 text-capitalize text-start heading">Welcome to the World of Mountain View</h2>
          <div>
            <ul className="fw-light fs-4 w-75">
              <li>Share your information and witness your dream world materialize.</li>
              <li>Access, review, and update your information at any time.</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-5 col-md-8 col-sm-10 p-3 p-md-5 position-relative">
          {
            isLoading && (
              <Bars color="#003da7" ariaLabel="bars-loading" wrapperClass="loadingBars" visible={true} />
            )
          }
          <form method='post' className='mt-5 d-flex flex-column' onSubmit={handleSubmit}>
            {stepToGo === "loginForm" ?
              (
                <>
                  {/* <div className="mb-3">
                    <label htmlFor="email" className="form-label m-0">Email *</label>
                    <input type="email" className="form-control Input" id="email" name='phoneNumber' required value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div> */}
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" className="form-label m-0">Phone Number *</label>
                    <input type="number" className="form-control Input" id="phoneNumber" name='phoneNumber' required value={phoneNumber} onChange={(e) => setPhoneNum(e.target.value)} />
                    <small className="text-secondary mt-1">* Please use the email & phone submitted in your contract.</small>
                  </div>

                  {showError && (
                    <div className="d-inline-block err-msg mb-4 p-2">Please call <b>16201</b> to verify your data</div>
                  )}

                  <div className="d-flex justify-content-center justify-content-md-start">
                    <button className="px-5 fs-5 rounded-pill btn btn-primary btnOTP py-2 fw-semibold" type="submit">Request OTP</button>
                    {/* <button className="px-5 fs-5 rounded-pill btnOTP py-2 fw-semibold" type="submit">Request OTP</button> */}
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-3">
                    <label htmlFor="otp_token" className="form-label m-0">OTP *</label>
                    <input type="number" className="form-control Input" id="otp_token" autoComplete='false' required value={otpToken} onChange={(e) => setotpToken(parseInt(e.target.value))} />
                    <small className="text-secondary mt-1">* Please use the code sent in SMS.</small>
                  </div>

                  {showError && (
                    <div className="d-inline-block err-msg mb-4 p-2">Please check your OTP</div>
                  )}

                  <div className="d-flex justify-content-center justify-content-md-start">
                    <button className="px-5 fs-5 rounded-pill btn btn-primary btnOTP py-2 fw-semibold" type="submit">Login</button>
                  </div>
                </>
              )
            }


          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
